  <template>
  <div class="">
      <div
        class="card mb-25 border-0 rounded-0 bg-white checkout-box letter-spacing "
      >
        <div class="card-body">
          <ul
          class="nav nav-tabs bg-white border-0 rounded-0"
          id="myTab"
          role="tablist"
          >
            <li  class="nav-item" role="tablist">
              <button 
                :class="['nav-link fs-14 fs-md-15 fs-lg-16 fw-semibold position-relative border-0 rounded-0 d-block w-100', { active: (selectedVersionType=='latest')?true:false }]"
                :aria-selected="(selectedVersionType=='latest')?true:false"
                role="button"
                @click="selectVersionType('latest')"
              >
              Aktuální počet instalací
              </button>
            </li>
            <li  class="nav-item" role="tablist">
              <button 
                :class="['nav-link fs-14 fs-md-15 fs-lg-16 fw-semibold position-relative border-0 rounded-0 d-block w-100', { active: (selectedVersionType=='overview')?true:false }]"
                :aria-selected="(selectedVersionType=='overview')?true:false"
                role="button"
                @click="selectVersionType('overview')"
              >
              Historicky nainstalované verze
              </button>
            </li>
          </ul>
        </div>
      <div class="row">
        <h4 class="fw-semibold mb-8">
          Produkt:
          <span class="fw-bold">{{(route.params.product == "all")?"Všechny":route.params.product}}</span>
        </h4>
      <div class="col-6">
        <div class="card-body">
          <ul
            class="nav nav-tabs bg-white border-0 rounded-0  align-text-bottom"
            id="myTab"
            role="tablist"
          >
          </ul>
          <span v-if="detailData.productOverview != undefined" class="row justify-content-left p-3">
            <div
              class="card mb-25 ml-1 border-0 rounded-0 bg-white letter-spacing col-auto"
            >
              <div class="card-body shift-card-body">
                <div class=" align-items-center">
                  <div class="">
                    <div class="product-details-content">
                      <ul class="info ps-0 list-unstyled d-inline-block">
                        <li class="text-paragraph fs-md-15 fs-xxxl-16">
                          <span class="text-black fw-semibold d-inline-block">Aktuální verze: </span>
                          <span class="overView-value">{{detailData.productOverview.currentVersion}}</span>
                        </li>
                        <li class="text-paragraph fs-md-15 fs-xxxl-16">
                          <span class="text-black fw-semibold d-inline-block">Počet instalací aktuální verze: </span>
                          <span class="overView-value">{{detailData.productOverview.currentVersionInstallationCount}}</span>
                        </li>
                        <li class="text-paragraph fs-md-15 fs-xxxl-16">
                          <span class="text-black fw-semibold d-inline-block">První datum instalace verze: </span>
                          <span class="overView-value">{{detailData.productOverview.currentVersionDate}}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card mb-25 ml-1 border-0 rounded-0 bg-white letter-spacing col-auto"
            >
                <div class="card-body shift-card-body">
                  <div class=" align-items-center">
                    <div class="">
                      <div class="product-details-content">
                        <ul class="info ps-0 list-unstyled d-inline-block">
                          <li class="text-paragraph fs-md-15 fs-xxxl-16">
                            <span class="text-black fw-semibold d-inline-block">Celkový počet unikátních instalací: </span>
                            <span class="overView-value">{{detailData.productOverview.totalInstallationCount}}</span>
                          </li>
                          <li class="text-paragraph fs-md-15 fs-xxxl-16">
                            <span class="text-black fw-semibold d-inline-block">Celkový počet FULL licencí: </span>
                            <span class="overView-value">{{detailData.productOverview.licenseFullCount}}</span>
                          </li>
                          <li class="text-paragraph fs-md-15 fs-xxxl-16">
                            <span class="text-black fw-semibold d-inline-block">Celkový počet DEMO licencí: </span>
                            <span class="overView-value">{{detailData.productOverview.licenseDemoCount}}</span>
                          </li>
                          <li class="text-paragraph fs-md-15 fs-xxxl-16">
                            <span class="text-black fw-semibold d-inline-block">Celkový počet TRIAL licencí: </span>
                            <span class="overView-value">{{detailData.productOverview.licenseTrialCount}}</span>
                          </li>
                          <li class="text-paragraph fs-md-15 fs-xxxl-16">
                            <span class="text-black fw-semibold d-inline-block">Celkový počet verzí: </span>
                            <span class="overView-value">{{detailData.productOverview.totalVersionCount}}</span>
                          </li>
                          <li class="text-paragraph fs-md-15 fs-xxxl-16">
                            <span class="text-black fw-semibold d-inline-block">Nejvíce instalovaná verze: </span>
                            <span class="overView-value">{{detailData.productOverview.mostInstalledVersion}}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </span>
        </div>
        </div>
        <div class="col-6">
          <!--<VersionCountGraph :detailData="detailData.products[selectedProductIndex]"/>-->
          <!--<VersionCountGraph :graphData="preparedGraphData" :graphLabel="prepareGraphLabel"/>-->
          <VersionCountColumnGraph :graphData="preparedGraphData" :graphLabel="prepareGraphLabel"/>
        </div>
      </div>
    </div>
  </div>
  <SelectionModal ref="selectionRef"/>
</template>

<script lang="ts">
//Vue import
import { defineComponent } from "vue";
import { useRoute, useRouter } from 'vue-router';
import { toRefs, ref, computed} from 'vue'

//Graph import
//import VersionCountGraph from "./VersionCountGraph.vue";
import VersionCountColumnGraph from "./VersionCountColumnGraph.vue";

//Modal import
import SelectionModal from "../../Common/SelectionModal.vue";

export default defineComponent({
  props: ['detailData'],
  name: "productDetail",
  emits: ['selectionChange'],
  components: {
   /// VersionCountGraph,
    VersionCountColumnGraph,
    SelectionModal,
  },
  setup(props, context){
    const route = useRoute();
    const router = useRouter();
    let { detailData } = toRefs(props);
    let selectedProductIndex = ref(0);
    let selectedVersionType = ref('latest');

    let selectionRef = ref<any>(null);

    const redirectToInstallationDetail = (appuudi :string) => {
      router.push({ name: 'Installation-detail', params: { appuuid: appuudi }})
    }
    const selectProduct = (index) =>{
      for(let key in detailData.value){
        if(key == index){
          selectedProductIndex.value = index;
        }
      }
    }
    const preparedGraphData = computed(() => {
      if(detailData.value.productLastestVersions != undefined){
        if(selectedVersionType.value == "overview"){
          return detailData.value.productVersions.map(function(item) { return {version: item["version"], count: item["installationCount"]} });
        }else if(selectedVersionType.value == "latest"){
          return detailData.value.productLastestVersions.result.map(function(item) { return {version: item["version"], count: item["installationCount"]} });
        }else{
          return detailData.value.productLastestVersions.result.map(function(item) { return {version: item["version"], count: item["installationCount"]} });
        }
      }else{
        return [];
      } 
    })
    const prepareGraphLabel = computed(() =>{
      if(detailData.value.productVersions != undefined){
        if(selectedVersionType.value == "overview"){
          return "Historicky nainstalované verze";
        }else if(selectedVersionType.value == "latest"){
          return "Aktuální počet instalací";
        }else{
          return "";
        }
      }else{
        return "";
      }
    })
    const selectVersionType = (historyType: string) => {
      context.emit('selectionChange', historyType);
      selectedVersionType.value = historyType;
    }
    const getSelectedVersionType =()=>{
      return selectedVersionType.value;
    }

    return{
      route,
      selectedProductIndex,
      preparedGraphData,
      selectedVersionType,
      selectVersionType,
      selectProduct,
      redirectToInstallationDetail,
      selectionRef,
      prepareGraphLabel,
      getSelectedVersionType
    }
  }
  

});
</script>

<style scoped>
.shift{
  margin-left: 50px;
}
.overView-value{
  padding: 5px;
}

</style>