<template>
  <div class="row justify-content-center">
    <div v-if="emailSuccessfullySend" class="col-md-10 col-lg-8 col-xl-9 col-xxl-8 col-xxxl-6">
      <div class="col-md-10 col-lg-8 col-xl-9 col-xxl-8 col-xxxl-6">
        <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
          <div class="card-body letter-spacing">
            <h4 class="text-black fw-bold mb-0 text-center">E-mail byl odeslán</h4>
            <p class="mb-0 fs-md-15 fs-lg-16 text-paragraph text-center sub-text">
                E-mail s údaji pro obnovení hesla byl odeslán na adresu: <br/> <b>{{email}}</b>
            </p>
            <div class="text-center mt-15 mt-sm-20 mt-md-25">
              <router-link
                to="/login"
                class="link-btn text-decoration-none text-primary fw-medium fs-md-15 fs-lg-16 position-relative"
                type="submit"
              >
                <i class="ph-bold ph-arrow-left"></i>
                Zpět na přihlášení
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="col-md-10 col-lg-8 col-xl-9 col-xxl-8 col-xxxl-6">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">
          <h4 class="text-black fw-bold mb-0 text-center">Zapomenuté heslo?</h4>
          <p class="mb-0 fs-md-15 fs-lg-16 text-paragraph text-center sub-text">
            Zde vložte e-mailovou adresu  <br/> Přijde vám email s postupem pro obnovení
            hesla
          </p>
          <form v-on:submit.prevent>
            <div class="form-group mb-15 mb-sm-20">
              <label class="d-block text-black fw-semibold mb-10">
                E-mailová adresa
              </label>
              <input
                v-model="email"
                type="email"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="email@gmail.com"
              />
            </div>
            <button
              @click="recoverPassword(email)"
              class="default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 d-block w-100"
            >
              Odeslat
            </button>
            <div class="text-center mt-15 mt-sm-20 mt-md-25">
              <router-link
                to="/login"
                class="link-btn text-decoration-none text-primary fw-medium fs-md-15 fs-lg-16 position-relative"
                type="submit"
              >
                <i class="ph-bold ph-arrow-left"></i>
                Zpět na přihlášení
              </router-link>
            </div>
            <div class="mb-0 fs-md-15 fs-lg-16 text-paragraph text-center text-danger">
              {{message}}
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {ref} from 'vue';

export default {
  name: "ForgotPassword",

  setup(){
    let email = ref("");
    let message = ref("");
    let emailSuccessfullySend = ref(false);
    const recoverPassword = (email) =>{
      axios.post("/api/requestPasswordRecovery",{
        email: email
      })
      .then((result)=>{
        if(result.data.status == 1){
          emailSuccessfullySend.value = true;
        }else{
          message.value = result.data.message;
          emailSuccessfullySend.value = false;
          setTimeout(function(){
						message.value = "";
					}, 2000);
        }
      })
      .catch((error)=>{
        console.log(error);
      })
    }
    return{
      email,
      emailSuccessfullySend,
      message,
      recoverPassword
    }
  }
};
</script>