<template>
  <div class="card mb-25 border-0 rounded-0 bg-white customer-satisfaction-box">
    <div class="card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing">
      <div
        class="mb-15 mb-md-25 d-flex align-items-center justify-content-between"
      >
        <h6 class="card-title fw-bold mb-0">{{graphTitle}}</h6>
      </div>
      <div v-if="preparedData.inputValues != undefined" id="customerSatisfactionChart" class="chart">
        <apexchart
          type="pie"
          height="240"
          :options="preparedData.chart"
          :series="preparedData.inputValues"
        ></apexchart>
      </div>
      <ul class="list ps-0 mb-0 list-unstyled text-center">
        <li v-for="(item, index) in preparedData.inputValues" :key="item.productName" class="d-inline-block text-start">
          <div class="d-flex align-items-center mb-10">
            <span class="d-block w-10 h-10 rounded-circle" :style="{ 'background-color': preparedData.chart.colors[index]}"></span>
            <span
              class="fs-12 fw-medium text-black-emphasis text-uppercase lh-1 ms-8"
            >
              {{preparedData.chart.labels[index]}}
            </span>
          </div>
          <div class="d-flex align-items-end">
            <h4 class="mb-0 text-black-emphasis lh-1 fw-black">{{item}}</h4>
            <span
              class="fw-bold text-danger d-inline-block position-relative top-1 ms-5"
            >
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { toRefs, ref, watch} from 'vue'

export default defineComponent({
  props: ['detailData', 'dataType', 'selectedCompany'],
  name: "CustomerSatisfaction",

  setup(props){

    let localDetailData = toRefs(props.detailData);
    let { dataType } = toRefs(props);
    let { selectedCompany } = toRefs(props);

    let graphTitle = ref("Přehled událostí")
    let preparedData = ref({});

    watch([dataType, selectedCompany], () => {
      preparedData.value = prepareData(props.dataType, props.selectedCompany);
    });

    watch(() => props.detailData, (newValue, oldValue) => {
      localDetailData = (newValue != undefined && newValue.result != undefined)?newValue.result:newValue;
      preparedData.value = prepareData(props.dataType, props.selectedCompany);
    });

    const prepareData = (dataType :string, selectedCompany :string) => {
      if(dataType == "event" && Array.isArray(localDetailData.events)){
        graphTitle.value = "Přehled událostí"
        let eventCounts = localDetailData.events.reduce((result, item) => {
          if (item.type in result) {
            result[item.type]++;
          } else {
            result[item.type] = 1;
          }
          return result;
        }, {});

        eventCounts = Object.keys(eventCounts).sort().reduce((result, key) => {
          result[key] = eventCounts[key];
          return result;
        }, {});

        let preparedData = {
          inputValues: Object.values(eventCounts),
          chart:{
            type: "pie",
            labels: Object.keys(eventCounts),
            colors: ["#6560F0", "#F1421B", "#00FF00", "#FFD700", "#00FFFF", "#FFA500", "#008000", "#FFFF00", "#800080" ],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              width: 0,
            },
            legend: {
              show: false,
            },
            tooltip: {
              style: {
                fontSize: "14px",
                fontFamily: "Red Hat Display, sans-serif",
              },
            },
          },
        }
        return preparedData;
      }else if(Array.isArray(localDetailData.companyEvents)){
        if(selectedCompany != null){
          graphTitle.value = "Aktuální přehled tabulek"
          let selectedCompanyIndex: number|null = null;
          let parsedData: any = null;
          if(selectedCompany == "all"){
            let mergedData = {};
            localDetailData.companyEvents.forEach(entry => {
              Object.keys(JSON.parse(entry.data)).forEach(key => {
                if (!mergedData[key]) {
                  mergedData[key] = 0;
                }     
                mergedData[key] += JSON.parse(entry.data)[key];
              });
            });
             parsedData = mergedData;
          }else{
            selectedCompanyIndex = localDetailData.companyEvents.findIndex((item) => item.firuuid == selectedCompany);
            parsedData = JSON.parse(localDetailData.companyEvents[selectedCompanyIndex].data);
          }

          let parsedDataWithouZeros = {};
          for (let key in parsedData) {
            if (parsedData[key] !== 0) {
              parsedDataWithouZeros[key] = parsedData[key];
              }
          }
          let preparedData = {
            inputValues: Object.values(parsedDataWithouZeros),
            chart:{
              type: "pie",
              labels: Object.keys(parsedDataWithouZeros),
              colors: ["#6560F0", "#F1421B", "#00FF00", "#FFD700", "#00FFFF", "#FFA500", "#008000", "#FFFF00", "#800080" ],
              dataLabels: {
                enabled: false,
              },
              stroke: {
                width: 0,
              },
              legend: {
                show: false,
              },
              tooltip: {
                style: {
                  fontSize: "14px",
                  fontFamily: "Red Hat Display, sans-serif",
                },
              },
            },
          }
        return preparedData;
        }
        return {};
      }else{
        return {};
      }
    }
    
    return{
      graphTitle,
      prepareData,
      preparedData,
    }
  }
});
</script>
<style>
.apexcharts-tooltip span {
	color: black !important;
}
</style>>