import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_DataTable, {
        tableData: _ctx.tableData,
        tableOptions: _ctx.tableOptions,
        onTableRestrictionChange: _ctx.handleTableRestrictionChange,
        ref: "tableDataRef"
      }, null, 8, ["tableData", "tableOptions", "onTableRestrictionChange"])
    ])
  ]))
}