<template>
  <div class="card mb-25 border-0 rounded-0 bg-white">
    <div class="card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing">
      <div class="d-sm-flex align-items-center justify-content-between">
        <h4 class="fw-semibold mb-8">
          Od:  
          <span class="fw-bold">{{borderDates.startDate}}</span>
          , 
          Do: 
          <span class="fw-bold">{{borderDates.endDate}}</span>
        </h4>
      </div>
      <div v-if="preparedData.task != undefined" id="taskOverviewChart" class="chart">
        <apexchart
          type="bar"
          height="370"
          :options="preparedData.taskOverviewChart"
          :series="preparedData.task"
        ></apexchart>
      </div> 
    </div>
  </div>
</template>

<script lang="ts">

//Vue importy
import { defineComponent, ref, watch, toRefs} from "vue";

export default defineComponent({
  props: ['graphData', 'borderDates'],
  name: "TaskOverview",
  setup(props){
    let { graphData } = toRefs(props);
    let preparedData = ref({});
    watch(graphData, () => {
      preparedData.value = prepareData();
    });
      const prepareData = () => {
      let preparedData = {
        task: [
          {
            name: "Počet použití evidence",
            data: graphData.value.data,
          },
        ],
        taskOverviewChart: {
        chart: {
          type: "bar",
          height: 370,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "24%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#6560F0"],
        stroke: {
          show: false,
        },
        xaxis: {
          categories: graphData.value.labels.map(item => new Date(item.minDate).toISOString().split("T")[0]+" - "+new Date(item.maxDate).toISOString().split("T")[0]),
          labels: {
            show: true,
            style: {
              fontFamily: "Red Hat Display, sans-serif",
              colors: "#9C9AB6",
              fontSize: "14px",
              fontWeight: 500,
            },
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        yaxis: {
          show: true,
          tickAmount: 6,
          labels: {
            show: true,
            style: {
              fontFamily: "Red Hat Display, sans-serif",
              colors: ["#9C9AB6"],
              fontSize: "14px",
              fontWeight: 500,
            },
          },
          axisBorder: {
            show: true,
            color: "#eee",
          },
        },
        fill: {
          opacity: 1,
          // type: 'gradient',
          // gradient: {
          //     opacityTo: 1,
          //     opacityFrom: .01,
          //     type: "vertical",
          //     stops: [0, 60, 100]
          // }
        },
        tooltip: {
          style: {
            fontSize: "14px",
            fontFamily: "Red Hat Display, sans-serif",
          },
        },
        grid: {
          show: true,
          strokeDashArray: 5,
          borderColor: "#d9e9ef",
        },
      },
      }
      return preparedData;
    }
    return{
      props,
      preparedData
    }
  }
});
</script>