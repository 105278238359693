import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Overview = _resolveComponent("Overview")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Overview, { overViewData: _ctx.overViewData }, null, 8, ["overViewData"])
  ]))
}