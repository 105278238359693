<template>
  <div class="row justify-content-center">
    <div class="col-md-10 col-lg-8 col-xl-9 col-xxl-8 col-xxxl-6">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">
          <h4 class="text-black fw-bold mb-0 text-center">
            Hermes
          </h4>
          <form v-on:submit.prevent>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Login
              </label>
              <input
                type="text"
                v-model="login"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="Přihlašovací jméno"
              />
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Heslo
              </label>
              <input
                type="password"
                v-model="password"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="Přihlašovací heslo"
              />
            </div>
            <div
              class="d-flex align-items-center justify-content-between mb-15 mb-md-20"
            >
              <div
                class="form-check form-check-primary mb-0 fs-md-15 fs-lg-16 text-muted lh-1"
              >
               <!-- <input
                  v-model="rememberMe"
                  @change="rememberChanged(rememberMe)"
                  class="form-check-input shadow-none"
                  type="checkbox"
                  id="remember-me"
                />
                <label class="form-check-label" for="remember-me">
                  Zapamatuj si
                </label>
                <div class = "messageBlock mt-2">
                  {{message}}
                </div>--> 
              </div>
              <router-link
                to="/forgot-password"
                class="forgot-password-btn fs-md-15 fs-lg-16 text-decoration-none position-relative text-primary"
              >
                Zapomenuté heslo
              </router-link>
            </div>
            <button
              class="default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 bg-success d-block w-100"
              type="submit"
              @click="loginUser(login, password)"
            >
              Přihlásit
            </button>
          </form>
            <div class="mb-0 mt-3 fs-md-15 fs-lg-16 text-paragraph text-center text-danger">
              {{message}}
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import { ref } from 'vue';
import { computed, onMounted } from 'vue';

import { useStore } from 'vuex';
import { useRouter } from 'vue-router'


//const token = useStore() 

export default {
  name: "LoginForm",
    setup(){
      const router = useRouter()
      const store = useStore();

      let rememberMe = ref(false);

      const user = computed(() => store.getters.getStoreUser);

      onMounted(()=>{
        let useRememeber = localStorage.getItem("rememberMe");
        if(useRememeber != undefined){
          rememberMe.value = useRememeber;
        }else{
          rememberMe.value = false;
          localStorage.setItem("rememberMe", true);
        }   
      })
      /* Login*/
      let login = ref(null);
      let password = ref(null);
      let message = ref(null);

      const loginUser = (login, password) =>{ 
        message.value = null;
        axios.post("/api/login",{
            login: login,
            password: password,
        })
        .then((result) => {
          if(result.data.status == 1){
            store.dispatch("fetchLoggedUser").then(result => {
              message.value = null;
              router.push({ name: 'DashboardMain' });
            });
          }else{
            message.value = result.data.message;
          }
        })
        .catch((error) => {
          if(error.response != undefined && error.response.data != undefined){
            message.value = error.response.data.message;
          }else if(error.message != undefined){
            message.value = error.message;
          }else{
            message.value = "Nastala chyba s přihlášením."
          }
        })
      }

      const rememberChanged = (rememberPassword) =>{ 
        localStorage.setItem("rememberMe", rememberPassword);
      }

      return {
        login,
        password,
        message,
        user,
        rememberMe,
        rememberChanged,
        loginUser
      }
      
    }
};
</script>
<style scoped>
.messageBlock{
  height: 13px;
  color: red;
}
</style>>