import { createWebHistory, createRouter } from "vue-router";

//Page components
import LoginPage from "../pages/Authentication/LoginPage.vue";
import ForgotPasswordPage from "../pages/Authentication/ForgotPassword.vue";
import ResetPasswordPage from "../pages/Authentication/ResetPassword.vue";
import DashboardMain from "../pages/Dashboard/DashboardPage.vue";
//import EventOverviewPage from "../pages/DataPreview/EventOverviewPage.vue";
import RawDataPage from "../pages/DataPreview/RawDataPage.vue";
import Installation from "../pages/Installation/InstallationMain.vue";
import InstallationDetail from "../pages/Installation/InstallationDetail.vue";
import InstallationInfo from "../pages/Installation/InstallationInfo.vue";
import Product from "../pages/Product/Product.vue";
import ProductDetail from "../pages/Product/ProductDetail.vue";
import Evidence from "../pages/Evidence/EvidenceMain.vue";
import EvidenceDetail from "../pages/Evidence/EvidenceDetail.vue";
import UserProfile from "../pages/User/Profile.vue";
import ErrorPage from "../pages/ErrorPage.vue";

import store from "../utils/vuexStore";

const routes = [
  {
    path: "/login",
    name: "LoginPage",
    beforeEnter: routeAuthorization,
    component: LoginPage,
    meta: {
      loginRequired: false,
      onlyNotLoggedIn: true
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    beforeEnter: routeAuthorization,
    component: ForgotPasswordPage,
    meta: {
      loginRequired: false,
      onlyNotLoggedIn: true
    },
  },
  {
    path: "/reset-password/:recoveryHash",
    name: "ResetPassword",
    beforeEnter: routeAuthorization,
    component: ResetPasswordPage,
    meta: {
      loginRequired: false,
      onlyNotLoggedIn: true
    },
  },
  {
    path: "/",
    name: "DashboardMain",
    beforeEnter: routeAuthorization,
    component: DashboardMain,
    meta: {
      loginRequired: true,
      onlyNotLoggedIn: false
    },
  },
  /*{
    path: "/overview",
    name: "EventOverviewPage",
    beforeEnter: routeAuthorization,
    component: EventOverviewPage,
    meta: {
      loginRequired: true,
      onlyNotLoggedIn: false
    },
  },*/
  {
    path: "/rawData",
    name: "RawDataPage",
    beforeEnter: routeAuthorization,
    component: RawDataPage,
    meta: {
      loginRequired: true,
      onlyNotLoggedIn: false
    },
  },
  {
    path: "/instalace",
    name: "Installation",
    beforeEnter: routeAuthorization,
    component: Installation,
    meta: {
      loginRequired: true,
      onlyNotLoggedIn: false
    },
  },
  {
    path: "/produkty",
    name: "Products",
    beforeEnter: routeAuthorization,
    component: Product,
    meta: {
      loginRequired: true,
      onlyNotLoggedIn: false
    },
  },
  {
    path: "/produkt-detail/:product",
    name: "Product-detail",
    beforeEnter: routeAuthorization,
    component: ProductDetail,
    meta: {
      loginRequired: true,
      onlyNotLoggedIn: false
    },
  },
  {
    path: "/evidence",
    name: "Evidence",
    beforeEnter: routeAuthorization,
    component: Evidence,
    meta: {
      loginRequired: true,
      onlyNotLoggedIn: false
    },
  },
  {
    path: "/evidence-detail/:product/:evidence",
    name: "Evidence-detail",
    beforeEnter: routeAuthorization,
    component: EvidenceDetail,
    meta: {
      loginRequired: true,
      onlyNotLoggedIn: false
    },
  },
  {
    path: "/instalace-detail/:appuuid",
    name: "Installation-detail",
    beforeEnter: routeAuthorization,
    component: InstallationDetail,
    meta: {
      loginRequired: true,
      onlyNotLoggedIn: false
    },
  },
  {
    path: "/instalace-info/:appuuid",
    name: "Installation-info",
    beforeEnter: routeAuthorization,
    component: InstallationInfo,
    meta: {
      loginRequired: true,
      onlyNotLoggedIn: false
    }
  },
  {
    path: "/profil",
    name: "User-profile",
    beforeEnter: routeAuthorization,
    component: UserProfile,
    meta: {
      loginRequired: true,
      onlyNotLoggedIn: false
    }
  },
  { path: "/:pathMatch(.*)*", name: "ErrorPage", component: ErrorPage },
]

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes: routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

function routeAuthorization (to: any, from :any, next: any){
  const loginRequired = to.meta.loginRequired;
  const onlyNotLoggedIn = to.meta.onlyNotLoggedIn;
  let logged = store.getters.getStoreLoggedIn;
  if (logged == null) {
    const watcher = store.watch(store.getters.getStoreLoggedInFunction, change => {
        logged = store.getters.getStoreLoggedIn;
        if(logged == false && loginRequired == false ){
          next();
        }else if(onlyNotLoggedIn == false && logged == false && loginRequired == true){
          next({ path: '/login' });  
        }else if(logged == true && loginRequired == true){
          next();
        }else{
          next({ path: '/' }) 
        }
      })
  }else {
    if(logged == false && loginRequired == false ){
      next();
    }else if(onlyNotLoggedIn == false && logged == false && loginRequired == true){
      next({ path: '/login' });  
    }else if(logged == true && loginRequired == true){
      next();
    }else{
      next({ path: '/' }) 
    }
  }
}

export default router;