<template>
  <div class="row">
    <div class="col-12">
      <Profile :profileData="profileData"/> 
    </div>   
  </div>
</template>

<script lang="ts">

import axios from 'axios';

import { onMounted } from "vue";
import { defineComponent } from "vue";
import { ref } from 'vue';

import Profile from "../../components/Implementation/User/Profile.vue";

export default defineComponent({
  name: "UserProfilePage",
  components: {
    Profile,
 },
  setup(){
    let profileData = ref([{}]);

    onMounted(() => {
      getProfileData();
    })

    const getProfileData = () => { 
      axios.get("/api/userProfile")
      .then((result) =>{
        if(result.data.status == 1){
            profileData.value = result.data.data;
        }
      }).catch((error) => {
        console.log(error);
      })
    }
    return{
        profileData
    }
  }

});

</script>