  <template>
  <div>
    <div class="row">
      <div class="card mb-25 border-0 rounded-0 bg-white checkout-box letter-spacing">
        <div class="card-body col-12">
          <ul
            class="nav nav-tabs bg-white border-0 rounded-0"
            id="myTab"
            role="tablist"
          >
            <li v-for="(item, index) in overViewData.productList" :key="item.productName" class="nav-item" role="tablist">
              <button 
                :class="['nav-link fs-14 fs-md-15 fs-lg-16 fw-semibold position-relative border-0 rounded-0 d-block w-100', { active: (index==selectedProductIndex)?true:false }]"
                role="button"
                :aria-selected="(index==selectedProductIndex)?true:false"
                @click="selectProduct(index)"
                >{{ item.text }}
              </button>
            </li>
          </ul>
        </div>
          <div class="row">
            <div class="col-6">
                <span class="col-12">
                  <span class="row">
                    <div class="form-group col-6">
                      <div class="input-group">
                        <span class="input-group-text rounded-0 fs-14 text-muted">
                          <i class="flaticon-calendar lh-1 position-relative top-1"></i>
                        </span>
                        <input
                          v-model="borderDates.startDate"
                          @change="setCustomDateSelection"
                          type="date"
                          class="form-control shadow-none rounded-0 text-black me-1"
                        />
                      </div>
                    </div>
                    <div class="form-group col-6">
                      <div class="input-group">
                        <span class="input-group-text rounded-0 fs-14 text-muted">
                          <i class="flaticon-calendar lh-1 position-relative top-1"></i>
                        </span>
                        <input
                          v-model="borderDates.endDate"
                          @change="setCustomDateSelection"
                          type="date"
                          class="form-control shadow-none rounded-0 text-black me-1"
                        />
                      </div>
                
                    </div>  
                  </span>
                  <span class="row">
                    <div class="col-6">
                      <label class="d-block text-black fw-semibold mb-10">Časové období:</label>
                      <select
                        v-model="timeSelected"
                        @change="changeBorderDates(timeSelected)"
                        class="form-select shadow-none fw-semibold rounded-0 col-6"
                      >
                        <option value="day" >Dnes</option>
                        <option value="week" >Týden</option>
                        <option value="month">Měsíc</option>
                        <option value="30days" >30 dní</option>
                        <option value="60days" >60 dní</option>
                        <option value="90days" >90 dní</option>
                        <option value="120days" >120 dní</option>
                        <option value="custom">Vlastní</option>
                      </select>
                    </div>
                    <div class="col-6">
                      <label class="d-block text-black fw-semibold mb-10">Typ licence</label>
                      <select
                        v-model="licenseSelected"
                        @change="getNewData()"
                        class="form-select shadow-none fw-semibold rounded-0 col-6"
                      >
                        <option value="FULL" >FULL</option>
                        <option value="DEMO" >DEMO</option>
                        <option value="TRIAL">TRIAL</option>
                      </select>
                    </div>
                  </span>
                </span>            
                <span class="row justify-content-left p-3">
                  <div
                    class="card mb-25 ml-1 border-0 rounded-0 bg-white letter-spacing col-auto"
                  >
                    <div class="card-body shift-card-body">
                      <div class=" align-items-center">
                        <div class="">
                          <div class="product-details-content">
                            <ul class="info ps-0 list-unstyled d-inline-block">
                              <li class="text-para fs-md-15 fs-xxxl-16">
                                <span class="text-black fw-semibold d-inline-block">Počet otevřených evidencí: </span>
                                <span class="overView-value">{{statisticsData.totalEvidenceCount}}</span>
                              </li>
                              <li class="text-paragraph fs-md-15 fs-xxxl-16">
                                <span class="text-black fw-semibold d-inline-block">Nejčastější unikátní evidence: </span>
                                  <a
                                    @click="redirectToEvidence(statisticsData.mostFrequentUniqueEvidence, overViewData.productList[selectedProductIndex].value)"
                                    class="d-inline-block fs-md-15 fs-lg-16 text-primary text-decoration-none"
                                    href="javascript:void(0);"  
                                  >
                                    {{capitalizeString(true, statisticsData.mostFrequentUniqueEvidence)}}
                                  </a>
                              </li>
                              <li class="text-paragraph fs-md-15 fs-xxxl-16">
                                <span class="text-black fw-semibold d-inline-block">Nejčastější unikátní evidence: </span>
                                  <a
                                    @click="redirectToEvidence(statisticsData.mostFrequentEvidence, overViewData.productList[selectedProductIndex].value)"
                                    class="d-inline-block fs-md-15 fs-lg-16 text-primary text-decoration-none"
                                    href="javascript:void(0);"  
                                  >
                                    {{capitalizeString(true, statisticsData.mostFrequentEvidence)}}
                                  </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card mb-25 ml-1 border-0 rounded-0 bg-white letter-spacing col-auto"
                  >
                    <div class="card-body shift-card-body">
                      <div class=" align-items-center">
                      </div>
                    </div>
                  </div>
                </span>  
              </div>
            <div class="col-sm-6 col-12 maximum-graph-size">
              <EvidencePieGraph :graphData="evidenceOverviewList" :borderDates="borderDates"/>
            </div>
          </div>
        </div>
      </div>
    <div>
      <DataTable :tableData="tableData" :tableOptions="tableOptions" @tableRestrictionChange="handleTableRestrictionChange" ref="tableDataRef"/>
    </div>
  </div>
</template>

<script lang="ts">

//Library imports
import axios from 'axios';

//Vue iports
import { defineComponent } from "vue";
import { useRouter } from 'vue-router';
import { toRefs, ref, onMounted} from 'vue'

//Component imports
import EvidencePieGraph from "../Evidence/EvidencePieGraph.vue";
import DataTable from "../../Common/SortableTable.vue"

//Dayjs imports
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export default defineComponent({
  props: ['overViewData'],
  name: "installationTable",
  components: {
    DataTable,
    EvidencePieGraph
  },
  setup(props){
    const router = useRouter();

    let { overViewData } = toRefs(props);
    let selectedProductIndex = ref(0);
    
    let licenseSelected = ref("FULL");

    let evidenceOverviewList = ref([]);

    //Time related varaibles
    let borderDates = ref<{ startDate: any, endDate: any }>({ startDate: new Date().toISOString().split("T")[0], endDate: new Date().toISOString().split("T")[0] });
    let timeSelected = ref("120days");

    //Table
    let tableOptions = ref({});
    let tableDataRef = ref<any>(null);
    let tableData = ref<any>({});
    let statisticsData = ref({});

    //Dayjs settings
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const dayjsInstance = dayjs().tz('Europe/Prague');

    onMounted(() => {
      prepareTableData();
      changeBorderDates(timeSelected.value);
		})

    const selectProduct = (index) =>{
      for(let key in overViewData.value.productList){
        if(key == index){
          selectedProductIndex.value = index;
        }
      }
      getEvidenceData(timeSelected.value, overViewData.value.productList[selectedProductIndex.value].value, licenseSelected.value);
    }
    const changeBorderDates = (dateRangeName :string) => {
      let startDate :Date;
      let endDate = dayjsInstance.startOf("day").toDate();
      if(dateRangeName == "30days"){
        startDate = dayjsInstance.subtract(30, 'days').startOf('day').toDate();
      }else if(dateRangeName == "60days"){
        startDate = dayjsInstance.subtract(60, 'days').startOf('day').toDate();
      }else if(dateRangeName == "90days"){
        startDate = dayjsInstance.subtract(90, 'days').startOf('day').toDate();
      }else if(dateRangeName == "120days"){
        startDate = dayjsInstance.subtract(120, 'days').startOf('day').toDate();
      }else if(dateRangeName == "day"){
        startDate = dayjsInstance.startOf('day').toDate();
        endDate = dayjsInstance.endOf('day').toDate();
      }else if(dateRangeName == "week"){
        startDate = dayjsInstance.startOf('week').toDate();
        endDate = dayjsInstance.endOf('week').toDate();
      }else if(dateRangeName == "month"){
        startDate = dayjsInstance.startOf('month').toDate();
        endDate = dayjsInstance.endOf('month').toDate();
      }else{
        startDate = dayjsInstance.startOf('day').toDate();
      }
      borderDates.value = {startDate: startDate.toISOString().split("T")[0], endDate: endDate.toISOString().split("T")[0]};
      getEvidenceData(timeSelected.value, overViewData.value.productList[selectedProductIndex.value].value, licenseSelected.value);
      return "done";
    }

    const getNewData = () =>{
      getEvidenceData(timeSelected.value, overViewData.value.productList[selectedProductIndex.value].value, licenseSelected.value);
    }

    const setCustomDateSelection = () =>{
      timeSelected.value = "custom";
      getEvidenceData(timeSelected.value, overViewData.value.productList[selectedProductIndex.value].value, licenseSelected.value);
    }

    const prepareTableData = () =>{
      let headers = [{text: "Evidence", key: "evidence", capitalizedString: true},{text: "Počet  použití", key: "usageCount"},{text: "Počet unikátních použití", key: "uniqueUsageCount"},{text: "Seznam instalaci", key: "installationList"}];
      let redirects = [{key: ["evidence", "product"], redirectToName: "Evidence-detail", paramName: ["evidence","product"]}];
      let defaultOrder = {orderBy: "uniqueUsageCount", orderDirection: "ASC"};
      let modalSelection = [{key: "installationList", redirectToName: "Installation-detail", paramName: "appuuid"}]
      tableOptions.value["headers"] = headers;
      tableOptions.value["redirects"] = redirects;
      tableOptions.value["defaultOrder"] = defaultOrder;
      tableOptions.value["modalSelection"] = modalSelection;
    }

    const handleTableRestrictionChange = () => {
      getEvidenceData(timeSelected.value, overViewData.value.productList[selectedProductIndex.value].value, licenseSelected.value);
    }

    const getEvidenceData = (dateRangeName :string, productName :string, licenseSelected :string) => { 
      let tableRestrictions = tableDataRef.value.tableRestrictions();
      let filterData = (tableRestrictions.filterData == undefined)?[]:tableRestrictions.filterData;
      tableRestrictions.filterData = filterData;
      axios.post("/api/evidenceData",{  
        productName: productName,
        license: licenseSelected,
        dateFrom: borderDates.value.startDate,
        dateTo: borderDates.value.endDate,
        dateRangeName: dateRangeName,
        tableRestrictions: tableRestrictions
      })
      .then((result) => {
        if(result.data.status == 1){
          evidenceOverviewList.value = result.data.data.list.fullResult;
          tableData.value.result = result.data.data.list.limitedResult;
          tableData.value.totalRecords = result.data.data.list.fullResult.length;
          tableData.value.result = tableData.value.result.map(item => ({...item, product: productName}));
          statisticsData.value = result.data.data.overview;
          tableOptions.value["filter"] = result.data.data.filterData;
        }
      })
      .catch((error) => {
        console.log("Nastala chyba: "+error);
      })
    }

    const redirectToEvidence = (evidenceName :string, productName: string) =>{
      router.push({name: "Evidence-detail", params: {evidence: evidenceName, product: productName}})
    }

    const capitalizeString = (useCapitalizedString :boolean, input :string) =>{
      if(input == undefined){
        return undefined;
      }
      if(useCapitalizedString == false || useCapitalizedString == undefined){
        return input;
      }else{
        let newString = String(input).toLowerCase();
        let firstUpperLetter = newString.charAt(0).toUpperCase();
        let remainingLetters = newString.slice(1);
        newString = firstUpperLetter + remainingLetters;
        return newString;
      }
    }

    return{
        selectProduct,
        selectedProductIndex,
        timeSelected,
        licenseSelected,
        borderDates,
        changeBorderDates,
        getNewData,
        setCustomDateSelection,
        evidenceOverviewList,
        statisticsData,
        dayjs,
        tableOptions,
        tableDataRef,
        tableData,
        handleTableRestrictionChange,
        redirectToEvidence,
        capitalizeString
    }
  }
  

});
</script>

<style scoped>

.shift{
  margin-left: 50px;
}

.maximum-graph-size{
  max-width: 500px;
  min-height: 417px;
  margin-left: auto;
  margin-right: auto;
}

.overView-value{
  padding: 5px;
}

</style>