import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7cbe9ef9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15" }
const _hoisted_4 = { class: "d-flex align-items-center" }
const _hoisted_5 = { class: "title ms-15 maxWidth" }
const _hoisted_6 = { class: "d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "fw-black mb-8 lh-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statBoxesData, (stat) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "col-xl-6 col-lg-4 col-xxxl-4 col-sm-6 stat-selected",
        key: stat.id
      }, [
        _createElementVNode("div", {
          onClick: ($event: any) => (_ctx.redirectToProductDetail(stat.productName)),
          class: "card mb-25 border-0 rounded-0 bg-white stats-box"
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                class: _normalizeClass([
                'icon position-relative rounded-circle text-center',
                stat.class,
              ])
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass(stat.icon)
                }, null, 2)
              ], 2),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(stat.text), 1),
                _createElementVNode("span", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(stat.values, (innerValues) => {
                    return (_openBlock(), _createElementBlock("span", {
                      key: innerValues,
                      class: _normalizeClass('col-'+innerValues.colsize)
                    }, [
                      _createTextVNode(_toDisplayString(innerValues.text) + " ", 1),
                      _createElementVNode("h4", _hoisted_8, _toDisplayString(innerValues.value), 1)
                    ], 2))
                  }), 128))
                ])
              ])
            ])
          ])
        ], 8, _hoisted_2)
      ]))
    }), 128))
  ]))
}