<template>
	<div>
		<div class="modal fade" :id="getModalId" tabindex="-1" aria-labelledby="confirmModalLabel" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="confirmModalLabel">{{title}}</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						{{text}}
					</div>
					<div class="modal-footer">
						<button type="button" @click="cancel" class="btn btn-secondary">Zrušit</button>
						<button type="button" @click="confirm" class="btn btn-primary">Potvrdit</button>
					</div>
				</div>
			</div>
		</div>
	</div>   
</template>

<script lang="ts">
import { defineComponent } from "vue";
import * as bootstrap from 'bootstrap';
import { ref, onMounted, computed } from 'vue';
import {generateRandomString} from "../../utils/shared";

export default defineComponent({
  name: "confirmModal",

  setup(props, context){
		let text = ref<string>();
		let title = ref<string>();

		let promise = ref<any>();
		let promiseResolve = ref<any>();
		let promiseReject = ref<any>();	

		let selectedElement = ref<any>();
		let modal = ref<any>();

    const showModal = (modalText: string, modalTitle: string) => {
			title.value = modalTitle;
			text.value = modalText;		

			selectedElement.value = document.querySelector("#"+getModalId.value);
			modal.value.toggle();
			promise.value = new Promise((resolve, reject) => {
				promiseResolve.value = resolve;
				promiseReject.value = reject;
			})
			return promise;
    };
    const confirm = () => {
			promiseResolve.value(true);
			modal.value.hide();
    };
		const cancel = () => {
			promiseResolve.value(false);
			modal.value.hide();
    };
		const getModalId = computed(() =>{
			let randomString = generateRandomString(20);
			return "Confrim-Modal-"+randomString;
    })
		onMounted(() => {
			selectedElement.value = document.querySelector("#"+getModalId.value);
			modal.value = new bootstrap.Modal(selectedElement.value);
		})
    return{
			showModal,
			text,
			title,
			confirm,
			cancel,
			getModalId
    }
  }
});
</script>