  <template>
  <div class="">	
		<span class="row">
			<div class="d-flex justify-content-center mb-5">
				<h4>Uživatelský profil</h4>
			</div>
			<div class="col-6">
				<label class="d-block text-black fw-semibold mb-10">Login:</label>
				<input
					v-model="localProfileData.login"
					class="form-control shadow-none rounded-0 text-black"
				/>
			</div>
			<div class="col-6">
				<label class="d-block text-black fw-semibold mb-10">E-mail</label>
				<input
					v-model="localProfileData.email"

					class="form-control shadow-none fw-semibold rounded-0 col-6"
				/>
			</div>
		</span>
		<div class="mt-3">
			<button 
				@click="goBack"
				class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0"
			>
				Zpět
			</button>
			<button 
				@click="updateProfile(localProfileData.login, localProfileData.email)"
				class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0"
			>
				Uložit
			</button>
		</div>
		<div class="mb-0 fs-md-15 fs-lg-16 text-center" :class="textClass">
			{{message}}
		</div>
  </div>
</template>

<script lang="ts">
import axios from "axios";

import { defineComponent, ref, watch, computed } from "vue";
import {  useRouter } from 'vue-router';


export default defineComponent({
  props: ['profileData'],
  name: "UserProfile",

	setup(props){
		let localProfileData = ref(props.profileData);
		let isSuccess = ref(true);
		let message = ref("");

		watch(() => props.profileData, () =>{
			localProfileData.value = props.profileData;
		})

		const textClass = computed(()=>{
			return (isSuccess.value)?"text-success":"text-danger";
		})

		const router = useRouter();
		const goBack = () =>{
			router.go(-1);
		}

		const updateProfile = (login: string, email: string) =>{
			axios.post("/api/updateUserProfile",{
				detailData:{
					login: login,
					email: email
				}
			})
			.then((result)=>{
				if(result.data.status == 1){
					isSuccess.value = true;
				}else{
					isSuccess.value = false;
				}
				message.value = result.data.message;
			})
			.catch((error)=>{
				isSuccess.value = false;
				message.value = (error.message != undefined)?error.message:error;
			})
		}
		return{
			goBack,
			localProfileData,
			updateProfile,
			textClass,
			message
		}
	}
	});
	</script>

	<style scoped>
	.shift{
	margin-left: 50px;
	}

</style>