<template>
  <div class="row">
 
    <div class="col-12">
      <Detail :detailData="detailData" @installationDetailChange="handleInstallationDetailChange" ref="detailRef"/>    
    </div>   
    <div class="col-12">
      <DataTable :tableData="tableData" :tableOptions="tableOptions" @tableRestrictionChange="handleTableRestrictionChange" ref="tableDataRef"/> 
    </div> 
    <button 
      class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0"
      @click="goBack"
    >
      Zpět
  </button>

 <!--
  <div >
    <div
    class="card mb-25 border-0 rounded-0 bg-white checkout-box letter-spacing "
    >
      <div class="card-body ">
        <ul
          class="nav nav-tabs bg-white border-0 rounded-0"
          id="myTab"
          role="tablist"
        >
            <div class="col-12">
              <Detail :detailData="detailData" @installationDetailChange="handleInstallationDetailChange" ref="detailRef"/>    
              <DataTable :tableData="tableData" :tableOptions="tableOptions" @tableRestrictionChange="handleTableRestrictionChange" ref="tableDataRef"/> 
            </div>
        </ul>
      </div>
    </div>
  </div> -->


  </div>
</template>
<script lang="ts">
import axios from 'axios';


import { onMounted } from "vue";
import { defineComponent } from "vue";
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import Detail from "../../components/Implementation/Installation/Detail.vue";
import DataTable from "../../components/Common/SortableTable.vue";

export default defineComponent({
  name: "DashboardPage",
  components: {
    Detail,
    DataTable,
  },
  setup(){
    let detailData = ref({products: []});

    let tableData = ref([{}]);
    let originalTableData = ref();//ref([{}]);
    let tableOptions = ref({});
    let tableDataRef = ref<any>(null);
    let detailRef = ref<any>(null);

    const route = useRoute();
    const router = useRouter();

    onMounted(() => {
      let appuuid = (route.params.appuuid) as string;
      getInstallationDetailData(appuuid);
    })

    const handleTableRestrictionChange = () => {
      getInstallationDetailData(route.params.appuuid as string);
    };

    const handleInstallationDetailChange = () =>{
      let selectedProductIndex = detailRef.value.getSelectedProductIndex();
      let installationType = detailRef.value.getInstallationType();
      if(installationType == "event"){
        tableData.value = prepareTableDataRows(originalTableData.value.products[selectedProductIndex].events);
        tableData.value["totalRecords"] = originalTableData.value.products[selectedProductIndex].eventCount;
        prepareTableData("event");
      }else{
        tableData.value = prepareTableDataRows(originalTableData.value.products[selectedProductIndex].versionHistory);
        tableData.value["totalRecords"] = originalTableData.value.products[selectedProductIndex].historyRecordsCount;
        prepareTableData("history");
      }
    }

    const getInstallationDetailData = (appuuid :string) => {
      let installationType = detailRef.value.getInstallationType();
      let selectedProductIndex = detailRef.value.getSelectedProductIndex();
      let tableRestrictions = tableDataRef.value.tableRestrictions();
      let filterData = (tableRestrictions.filterData == undefined)?[]:tableRestrictions.filterData;
      tableRestrictions.filterData = filterData;
      axios.post("/api/installationDetail",{
        appuuid: appuuid,
        tableRestrictions: tableRestrictions,
        installationType: installationType
      })
      .then((result) =>{
        if(result.data.status == 1){
          originalTableData.value = result.data.data;
          if(installationType == "event"){
            tableData.value["result"] = prepareTableDataRows(result.data.data.products[selectedProductIndex].events);
            tableData.value["totalRecords"] = result.data.data.products[selectedProductIndex].eventCount;
          }else{
            tableData.value["result"] = prepareTableDataRows(result.data.data.products[selectedProductIndex].versionHistory);
            console.log(result.data.data.products[selectedProductIndex])
          }
          detailData.value = result.data.data;
          prepareTableData(installationType);
        }
      })
    }

    const prepareTableDataRows = (data) =>{
      let installtionType = detailRef.value.getInstallationType();
      if(installtionType == "event"){
        let newData = data.map(item => ({type: item.type, version: item.appinfo.version, license: item.appinfo.license, timestamp: item.timestamp}))
        return newData;
      }else{
        let newData = data.map(item => ({type: item.type, currentVersion: item.previousVersion, previousVersion: item.currentVersion, license: item.license, versionChangeTime: item.versionChangeTime}))
        return newData;
      }
    }

    const prepareTableData = (dataType: string) =>{
      let headers = [{}];
      if(dataType == "event"){
        headers = [{text: "Typ", key: "type"}, {text: "Verze", key: "version"}, {text: "License", key: "license"}, {text: "Datum", key: "timestamp"}];
      }else{
        headers = [{text: "Typ", key: "type"}, {text: "Původní verze ", key: "previousVersion"}, {text: "Současná verze", key: "currentVersion"}, {text: "License", key: "license"}, {text: "Datum", key: "versionChangeTime"}];
      }  
      let redirects = [];//[{key: "appuuid", redirectToName: "Installation-detail", paramName: "appuuid"}];
      tableOptions.value["headers"] = headers;
      tableOptions.value["redirects"] = redirects;
    }

    const goBack = () =>{
      router.go(-1);
    }
    return{
      detailData,
      tableData,
      tableOptions,
      tableDataRef,
      detailRef,
      goBack,
      handleTableRestrictionChange,
      handleInstallationDetailChange
    }
  }
});

</script>
<style scoped>
.test{
  min-height: 30px;
  text-align: center;
}
</style>