<template>
  <div class="row">
    <div class="col-12">
      <Detail :detailData="detailData"  @selectionChange="handleSelectionChange" ref="detailRef"/>    
    </div>  
  </div>
  <div class="row">
    <div class="col-12">
      <DataTable :tableData="tableData" :tableOptions="tableOptions" @tableRestrictionChange="handleTableRestrictionChange" ref="tableDataRef"/> 
    </div>   
  </div>
      <button 
      class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0"
      @click="goBack"
    >
      Zpět
    </button>
</template>
<script lang="ts">
import axios from 'axios';


import { onMounted } from "vue";
import { defineComponent } from "vue";
import { ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import Detail from "../../components/Implementation/Product/Detail.vue";
import DataTable from "../../components/Common/SortableTable.vue";

export default defineComponent({
  name: "DashboardPage",
  components: {
    Detail,
    DataTable
  },
  setup(){
    const route = useRoute();
    const router = useRouter();

    let detailData = ref({});
    let detailRef = ref<any>(null);
    let selection = ref("latest");

    let tableData = ref([{}]);
    let tableOptions = ref({});
    let tableDataRef = ref<any>(null);

    onMounted(() => {
      let product = (route.params.product) as string;
      getProductDetailData(product);
    })
    const getProductDetailData = (product :string) => { 
      let tableRestrictions = tableDataRef.value.tableRestrictions();   

      axios.post("/api/productDetail",{
        selected: selection.value,
        product: product,
        tableRestrictions:{
          orderBy: tableRestrictions.orderBy,
          orderDirection: tableRestrictions.orderDirection,
          paginatorCurrentPage: tableRestrictions.paginatorCurrentPage,
          paginatorPerPage: tableRestrictions.paginatorPerPage
        }
      })
      .then((result) =>{
        if(result.data.status == 1){
          if(selection.value == "overview"){
            tableData.value = result.data.data.productVersions;
            prepareTableData("overview");
          }else{
            tableData.value = result.data.data.productLastestVersions;
            prepareTableData("latest");
          }  
          detailData.value = result.data.data;

        }
      })
    }
    const handleTableRestrictionChange= () => {
      let product = (route.params.product) as string;
      getProductDetailData(product);
    };
    const handleSelectionChange = (selected :string)=>{
      selection.value = selected;
      if(selected == "overview"){
        tableData.value = detailData.value["productVersions"];
        prepareTableData("overview");
      }else{
        tableData.value = detailData.value["productLastestVersions"];
        prepareTableData("latest");
      }
    }
    const prepareTableData = (selected :string) =>{
      if(selected == "overview"){
        let headers = [{text: "Verze", key: "version"},{text: "Počet instalací", key: "installationCount"},{text: "Počet full licencí", key: "licenseFullCount"},{text: "Počet demo licencí", key: "licenseDemoCount"},{text: "Počet trial licencí", key: "licenseTrialCount"}
        ,{text: "Datum první instalace", key: "firstVersionDate"},{text: "Datum poslední instalace", key: "lastVersionDate"},{text: "Poslední appuuid", key: "lastAppuuid"}];
        let redirects = [{key: "lastAppuuid", redirectToName: "Installation-detail", paramName: "appuuid"}];
        tableOptions.value["headers"] = headers;
        tableOptions.value["redirects"] = redirects;
      }else{
        let headers = [{text: "Verze", key: "version"},{text: "Licence", key: "license"},{text: "Počet licencí", key: "installationCount"},{text: "Datum první verze", key: "firstVersionDate"},{text: "Datum poslední verze", key: "lastVersionDate"}
        ,{text: "Seznam appuuid", key: "appuuidList"}];
        let modalSelection = [{key: "appuuidList", redirectToName: "Installation-detail", paramName: "appuuid"}]
        let redirects = [];
        tableOptions.value["headers"] = headers;
        tableOptions.value["redirects"] = redirects;
        tableOptions.value["modalSelection"] = modalSelection;
      }
    }
    const goBack = () =>{
      router.go(-1);
    }
    return{
        detailData,
        detailRef,
        tableData,
        tableOptions,
        tableDataRef,
        handleTableRestrictionChange,
        handleSelectionChange,
        goBack
    }
  }
});

</script>
<style scoped>
.test{
  min-height: 30px;
  text-align: center;
}
</style>>