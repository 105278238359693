  <template>
  <div class="">
    <EvidenceFrequencyGraph :graphData="detailData.graphData" :borderDates="borderDates"/>
    <button 
      class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0"
      @click="goBack"
    >
      Zpět
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {  useRouter } from 'vue-router';

//Component import
import EvidenceFrequencyGraph from "./EvidenceFrequencyGraph.vue";

export default defineComponent({
  props: ['detailData', 'borderDates'],
  name: "installationTable",
  components: {
    EvidenceFrequencyGraph
  },
  setup(){
    const router = useRouter();
    const goBack = () =>{
      router.go(-1);
    }
    return{
      goBack
    }
  }
});
</script>

<style scoped>
.shift{
  margin-left: 50px;
}

</style>