<template>
  <div class="row">
    <div class="col-12">
      <StatsBoxes :statBoxesData="statBoxesData"/>    
    </div>  
  </div>
  <div class="row">
    <div class="col-12">
      <DataTable :tableData="tableData" :tableOptions="tableOptions" @tableRestrictionChange="handleTableRestrictionChange" ref="tableDataRef"/> 
    </div>   
  </div>
</template>

<script lang="ts">
import axios from 'axios';


import { onMounted } from "vue";
import { defineComponent } from "vue";
import { ref } from 'vue';

//import DataTable from "../../components/Dashboard/Installation/InstallationTable.vue";
import DataTable from "../../components/Common/SortableTable.vue";
import StatsBoxes from "../../components/Implementation/Installation/StatsBoxes.vue";

export default defineComponent({
  name: "DashboardPage",
  components: {
    DataTable,
    StatsBoxes,
  },
  setup(){
    let statBoxesData = ref([{}]);
    let tableData = ref([{}]);
    let tableOptions = ref({});
    let tableDataRef = ref<any>(null);

    onMounted(() => {
      getInstallationData();
      getInstallationList();
    })

    const handleTableRestrictionChange= () => {
      getInstallationList();
    };

    const getInstallationData = () => { 
      axios.post("/api/installationOverview", {
      }).then((result) =>{
        if(result.data.status == 1){
          statBoxesData.value = result.data.data.generalStats;
        }
      })
    }

    const getInstallationList = () => { 
      let tableRestrictions = tableDataRef.value.tableRestrictions();
      let filterData = (tableRestrictions.filterData == undefined)?[]:tableRestrictions.filterData; 
      axios.post("/api/installationList",{
        orderBy: tableRestrictions.orderBy,
        orderDirection: tableRestrictions.orderDirection,
        paginatorCurrentPage: tableRestrictions.paginatorCurrentPage,
        paginatorPerPage: tableRestrictions.paginatorPerPage,
        filterData: filterData
      })
      .then((result) =>{
        if(result.data.status == 1){
          tableData.value = result.data.data;
          tableOptions.value["filter"] = result.data.data.filterData;
          prepareTableData();
        }
      })
    }
    
    const prepareTableData = () =>{
      let headers = [{text: "Appuuid", key: "appuuid"},{text: "Počet událostí", key: "eventCount"},{text: "Počet firem", key: "latestCompanyCount"},{text: "Datum první události", key: "firstEventDate"},{text: "Datum poslední události", key: "lastEventDate"}];
      let redirects = [{key: "appuuid", redirectToName: "Installation-detail", paramName: "appuuid"}];
      tableOptions.value["headers"] = headers;
      tableOptions.value["redirects"] = redirects;
    }
    return{
      handleTableRestrictionChange,
      tableDataRef,
      statBoxesData,
      tableData,
      tableOptions,
      getInstallationData
    }
  }

});

</script>