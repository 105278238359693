  <template>
  <div class="row">
    <div
      class="col-xl-6 col-lg-4 col-xxxl-4 col-sm-6 stat-selected"
      v-for="stat in statBoxesData"
      :key="stat.id"
    >
      <div 
        @click="redirectToProductDetail(stat.productName)"
        class="card mb-25 border-0 rounded-0 bg-white stats-box"
      >
        <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
          <div class="d-flex align-items-center">
            <div
              :class="[
                'icon position-relative rounded-circle text-center',
                stat.class,
              ]"
            >
              <i :class="stat.icon"></i>
            </div>
            <div class="title ms-15 maxWidth">
              <span
                class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis"
              >
                {{ stat.text }}
              </span>
              <span class="row" >
              <span
                v-for="innerValues in stat.values"
                :key="innerValues"
                :class="'col-'+innerValues.colsize"
              > 
                  {{innerValues.text}}
                <h4 class="fw-black mb-8 lh-1">
                 {{ innerValues.value }}
                </h4>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { useRouter} from "vue-router";

export default defineComponent({
  props: ['statBoxesData'],
  name: "StatsBoxes",
  setup(props){
    const router = useRouter();
    let localStatBoxesData = ref(props.statBoxesData);

    watch(() => props.statBoxesData, (newValue, oldValue) => {
      localStatBoxesData.value = (newValue.result != undefined)?newValue.result:newValue;
      for(let key in localStatBoxesData.value){
        localStatBoxesData.value[key].values.sort((a, b) => {
            const order = { "FULL": 1, "TRIAL": 2, "DEMO": 3 };
            return order[a.text] - order[b.text];
          });
        //Abecední řazení
         //localStatBoxesData.value[key].values = localStatBoxesData.value[key].values.sort((a, b) => a.text.localeCompare(b.text));
      }
    })

    const redirectToProductDetail = (productName :string) =>{
      router.push({ name: 'Product-detail', params: { product: productName }})
    }
    return{
      redirectToProductDetail,
      localStatBoxesData
    }
  }
});

</script>

<style scoped>
.maxWidth{
  width: 100%
}
.stat-selected :hover{
  background-color: #e5e5e5;
  transition: background-color 0.3s ease;
}
</style>>