<template>
  <div class="row">
    <div class="col-12">
      <DataTable :tableData="tableData" :tableOptions="tableOptions" @tableRestrictionChange="handleTableRestrictionChange" ref="tableDataRef"/> 
    </div>   
  </div>
</template>

<script lang="ts">
import axios from 'axios';

import { onMounted } from "vue";
import { defineComponent } from "vue";
import { ref } from 'vue';

import DataTable from "../../components/Common/SortableTable.vue";

export default defineComponent({
  name: "DashboardPage",
  components: {
    DataTable,
 },
  setup(){
    let statBoxesData = ref([{}]);

    //Table variables
    let tableData = ref([{}]);
    let tableOptions = ref({});
    let tableDataRef = ref<any>(null);

    onMounted(() => {
      prepareTableData();
      getRawData();
    })

    const handleTableRestrictionChange= () => {
      getRawData();
    };

    const getRawData = () => { 
      let tableRestrictions = tableDataRef.value.tableRestrictions(); 
      let filterData = (tableRestrictions.filterData == undefined)?[]:tableRestrictions.filterData;
      axios.post("/api/rawData",{
        orderBy: tableRestrictions.orderBy,
        orderDirection: tableRestrictions.orderDirection,
        paginatorCurrentPage: tableRestrictions.paginatorCurrentPage,
        paginatorPerPage: tableRestrictions.paginatorPerPage,
        filterData: filterData
      })
      .then((result) =>{
        if(result.data.status == 1){
          tableOptions.value["filter"] = result.data.data.filterData;
          tableData.value = result.data.data;
          prepareTableData();
        }
      }).catch((error) => {
        console.log(error);
      })
    }
    
    const prepareTableData = () =>{
      let headers = [{text: "Appuuid", key: "appuuid"},{text: "Typ", key: "type"},{text: "Appinfo", key: "appinfo"},{text: "FirInfo", key: "firinfo"},{text: "Data", key: "data"},{text: "Čas", key: "timestamp"}];
      let redirects = [{key: "appuuid", redirectToName: "Installation-detail", paramName: "appuuid"}];
      let defaultOrder = {orderBy: "timestamp", orderDirection: "DESC"};
      //let filter = [{type: "select", text: "typ", value: ["update", "upgrade", "import"]},{type: "multiSelect", text: "verze", value: ["2002.2.1", "2002.3.11", "2003.6.5"]}]
     /* let filter = [{type: "multiselect", name:"oznaceni", subobject: "appinfo", options:["A","B"], value:[]}, {type: "between", name:"timestamp", subobject: null,
      value:{from: null, to: null}}, {type: "singleSelect", name:"cena", subobject: "appinfo", options: ["test","test2"], value:null}, {type: "fulltext", name:"cena", subobject: "appinfo", options:null, value: null}]*/
     /* [{type: "between", name:"timestamp", subobject: null, value:{from: null, to: null}}, {type: "fulltext", name:"appuuid", text: "Appuuid", subobject: null, value: null}, 
      {type: "multiselect", name:"type", text: "Typ", options:["formsStats", "update", "upgrade"], subobject: null, value: null},
      {type: "multiselect", name:"product", text: "Produkt", options:["DUNA", "MEDIC", "CASH"], subobject: "appinfo", value: null}
      ]*/

      tableOptions.value["headers"] = headers;
      tableOptions.value["redirects"] = redirects;
      tableOptions.value["defaultOrder"] = defaultOrder;
     // tableOptions.value["filter"] = filter;

    }
    return{
      handleTableRestrictionChange,
      tableDataRef,
      statBoxesData,
      tableData,
      tableOptions,
      getRawData
    }
  }

});

</script>