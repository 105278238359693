<template>
<div>
	<div class="modal fade" :id="getModalId" tabindex="-1" aria-labelledby="selectionModalLabel" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header d-flex justify-content-center">
					<h5 class="modal-title" id="selectionModalLabel">{{title}}</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="row me-5 ms-5 mb-2">
					<div class="align-items-center col-7">
					</div>
					<div class="form-group col-5">
						<input
							type="text"
							v-model="selectionFulltextFilter"
							@keyup="filterSelection(selectionFullTextFilter)"
							class="form-control shadow-none text-black rounded-0"
							placeholder="Filtr"
						/>
					</div>
				</div>
				<table class="text-center">
					<tr class="text-center" v-for="(row, index) in matrix" :key="index">
						<td class="text-center" v-for="(item, colIndex) in row" :key="colIndex">
							<a
								class="d-flex justify-content-center dropdown-item d-flex align-items-center fw-bold text-primary"
								href="javascript:void(0);"  
								@click="handleRedirect(item)" 
							>
							{{(item.propsValue.text != "" && item.propsValue.text != null)?item.propsValue.text.slice(0,13):item.propsValue.value.slice(0,13)}}
							</a>
							</td>
					</tr>
				</table>
				<div class="modal-footer">
					<button type="button" @click="cancel" class="btn btn-secondary">Zrušit</button>
				</div>
			</div>
		</div>
	</div>
</div>   
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from 'vue-router'
import * as bootstrap from 'bootstrap';
import { ref, onMounted, computed } from 'vue';
import {generateRandomString} from "../../utils/shared";

export default defineComponent({
  name: "selectionModal",

  setup(){
		const router = useRouter();

		let selectionFulltextFilter = ref<string>("");

		let text = ref<string>();
		let title = ref<string>();
		let values = ref<any[]>([]);

		let promise = ref<any>();
		let promiseResolve = ref<any>();
		let promiseReject = ref<any>();	

		let selectedElement = ref<any>();
		let modal = ref<any>();

		let matrix = ref<any[]>([]);

    const showModal = (modalText: string, modalTitle: string, modalValues: [{text :string, value :string, redirectTo :string, propsValue :string}]) => {
			title.value = modalTitle;
			text.value = modalText;		
			values.value = modalValues;
			if(modalValues != undefined){
				createMatrix();
			}
			selectedElement.value = document.querySelector("#"+getModalId.value);
			modal.value.toggle();
			promise.value = new Promise((resolve, reject) => {
				promiseResolve.value = resolve;
				promiseReject.value = reject;
			})
			return promise;
    };
		const createMatrix = () =>{
			let tempValues = values.value.filter(item1 => Object.keys(item1.propsValue).some(item2 => String(item1.propsValue[item2]).toLowerCase().includes(selectionFulltextFilter.value.toLowerCase())));
			let rows = Math.ceil(tempValues.length / 3);
			let rowIndex = 0;
			let colIndex = 0;
			matrix.value = [];
			for (let i = 0; i < rows; i++) {
				matrix.value.push([]);
				for (let j = 0; j < 3; j++) {
					if (tempValues[rowIndex]) {
						matrix.value[i][colIndex] = tempValues[rowIndex];
						rowIndex++;
						colIndex++;
					} else {
						break;
					}
				}
				colIndex = 0;     
			}
		}
		const handleRedirect = (selection :any) =>{
			let redirectValue = (selection.propsValue.value != undefined)?selection.propsValue.value: selection.propsValue;
			router.push({ name: selection.redirectTo, params: { [selection.propsName]: redirectValue }})

			promiseResolve.value();
			modal.value.hide();
		}
		const cancel = () => {
			promiseResolve.value(false);
			modal.value.hide();
    };
		const getModalId = computed(() =>{
			let randomString = generateRandomString(20);
			return "Confrim-Modal-"+randomString;
    })
		const filterSelection = () =>{
			createMatrix();
			return 0;
		}
		onMounted(() => {
			selectedElement.value = document.querySelector("#"+getModalId.value);
			modal.value = new bootstrap.Modal(selectedElement.value);
		})
    return{
			showModal,
			text,
			title,
			values,
			handleRedirect,
			cancel,
			getModalId,
			matrix,
			filterSelection,
			selectionFulltextFilter
    }
  }
});
</script>