<template>
  <div>
		<template v-if="localCondition.type === 'multiselect'">
			<div class="col-12">
				<label>{{localCondition.text}}</label>
				<VueMultiselect v-if="(typeof localCondition.options[0] == 'object')" v-model="localCondition.value" :options="localCondition.options" :multiple="true" display="value" :custom-label="createLabels" label="text" track-by="value" :placeholder="localCondition.text" />
				<VueMultiselect v-else v-model="localCondition.value" :options="localCondition.options" :multiple="true" display="value" :custom-label="createLabels" :placeholder="localCondition.text" />
			</div>
		</template>
		<template v-else-if="localCondition.type === 'between'">
			<div class="row">
				<div class="col-6">
					<label>OD</label>
					<input
						v-model="localCondition.value.from"
						@change="setCustomDateSelection"
						type="date"
						class="form-control shadow-none rounded-0 text-black me-1"
						/>
						</div>
						<div class="col-6">
					<label>DO</label>
					<input
						v-model="localCondition.value.to"
						@change="setCustomDateSelection"
						type="date"
						class="form-control shadow-none rounded-0 text-black me-1"
					/>
				</div>
			</div>
		</template>
		<template v-else-if="localCondition.type === 'select'">
			<div class="col-12">
				<VueMultiselect v-model="localCondition.value" :options="localCondition.options"/>
			</div>
		</template>
		<template v-else-if="localCondition.type === 'fulltext'">
			<label>{{localCondition.text}}</label>
			<div class="col-12">
				<input
					v-model="localCondition.value"
					type="text"
					class="form-control shadow-none rounded-0 text-black"
					:placeholder="localCondition.name"
				/>
			</div>
		</template>
		<div class="container mt-5">
			</div>
  </div>
</template>

<script lang="ts">

import { onMounted } from "vue";
import { defineComponent } from "vue";
import { ref, watch } from 'vue';

import VueMultiselect from 'vue-multiselect';

export default defineComponent({
  props: ['condition'],
  name: "DashboardPage",
	emits: ['tableFilterChanged'],
	components: {
		VueMultiselect
	},
  setup(props, context){
		let localCondition = ref(props.condition);
		let filterData = ref<any>([]);
		let filterValues = {};

		let multiSelect = ref([]);
		let options = ref([]);

    onMounted(() => {
    //  prepareFilterData();
		})

		watch(() => localCondition, (newVal, oldVal) => {
			context.emit('tableFilterChanged', localCondition.value);
		}, { deep: true });

		const returnFilterData = () => {
      return localCondition.value;
    }
		const resetFilter = () => {
			if(localCondition.value.type == "between"){
				localCondition.value.value.from = null;
				localCondition.value.value.to = null;
			}else{
				localCondition.value.value = null
			}
		}

		const createLabels = (input) =>{
			if(typeof input == "object"){
				return input.text;
			}else{
				return input;
			}
		}

    return{
      filterData,
      filterValues,
			localCondition,
			returnFilterData,
			options,
			multiSelect,
			resetFilter,
			createLabels
    }
  }

});

</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>