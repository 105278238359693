<template>
  <div
    :class="[
      'sidebar-area position-fixed start-0 top-0 bg-black h-100vh transition',
      { active: stateStoreInstance.open },
    ]"
    id="sidebar-area"
  >
    <div class="logo position-absolute start-0 end-0 top-0 bg-black">
      <router-link
        to="/"
        class="d-flex align-items-center text-white text-decoration-none"
      >
        <img src="../../assets/images/favicon.png" alt="logo-icon" />
        <span class="fw-bold ms-10">Hermes</span>
      </router-link>
      <div class="border-bottom"></div>
      <button
        class="sidebar-burger-menu position-absolute lh-1 bg-transparent p-0 border-0"
        @click="stateStoreInstance.onChange"
      >
        <i class="ph-duotone ph-caret-double-right"></i>
      </button>
    </div>
    <div class="sidebar-menu">
      <ul
        class="sidebar-navbar-nav ps-0 mb-0 list-unstyled accordion"
        id="sidebarNavAccordion"
      >
        <li class="sidebar-nav-item">
          <router-link to="/" class="sidebar-nav-link d-block">
            <i class="flaticon-more-1"></i>
            <span class="title">Dashboard</span>
          </router-link>
        </li>
       <!-- <li class="sidebar-nav-item">
          <router-link to="/overview" class="sidebar-nav-link d-block">
            <i class="flaticon-settings"></i>
            <span class="title">Přehled</span>
          </router-link>
        </li>-->
        <li class="sidebar-nav-item">
          <router-link to="/instalace" class="sidebar-nav-link d-block">
            <i class="flaticon-users-group"></i>
            <span class="title">Instalace</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="/produkty" class="sidebar-nav-link d-block">
            <i class="flaticon-3d-cube"></i>
            <span class="title">Produkty</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="/evidence" class="sidebar-nav-link d-block">
            <i class="flaticon-book"></i>
            <span class="title">Evidence</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="/rawData" class="sidebar-nav-link d-block">
            <i class="flaticon-folder"></i>
            <span class="title">Nezpracovaná data</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="#" class="sidebar-nav-link d-block">
            <i class="flaticon-logout"></i>
            <span @click="logout" class="title">Logout</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
  <ConfirmModal ref="confirmRef"/>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import stateStore from "../../utils/store";
import { useStore } from 'vuex';

import ConfirmModal from "../Common/ConfirmModal.vue";

export default defineComponent({
  name: "MainSidebar",
  components: {
    ConfirmModal
  },
  setup() {
    const stateStoreInstance = stateStore;
    const store = useStore();

    let confirmRef = ref<any>(null);

    async function logout(){
      let confirmLogout = await confirmRef.value.showModal("Opravdu se chcete odhlásit?", "Odhlášení").value
      if(confirmLogout == true){
        store.dispatch("fetchLogout", { })
      }
    }
    return {
      stateStoreInstance,
      logout,
      confirmRef
    };
  },
});
</script>