<template>
  <div class="card mb-25 border-0 rounded-0 bg-white customer-satisfaction-box">
    <div class="card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing">
      <div
        class="mb-15 mb-md-25 d-flex align-items-center justify-content-between"
      >
        <!--<h6 class="card-title fw-bold mb-0">Verze</h6>-->
        </div>
        <div id="wrapper">
          <div v-if ="preparedData.series != undefined" id="chart">
            <apexchart type="bar" height="350" :options="preparedData.chartOptions" :series="preparedData.series"></apexchart>
          </div>
      </div>
    </div>
		<span>
			<label class="d-block text-black fw-semibold mb-10">Ignorovat záznamy s hodnotou a menší:</label>
			<select
				v-model="ignoreLowerThan"
				@change="refreshdata()"
				class="form-select shadow-none fw-semibold rounded-0"
			>
				<option value="0">Bez omezení</option>
				<option value="1%">1% Nejmenších</option>
				<option value="5%">5% Nejmenších</option>
				<option value="1">1</option>
				<option value="5">5</option>
				<option value="10">10</option>
			</select>
		</span>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from "vue";
  import { toRefs, ref, watch } from 'vue'

  export default defineComponent({
    props: ['graphData','graphLabel'],
    name: "CustomerSatisfaction",
    setup(props){
      let { graphData, graphLabel } = toRefs(props);
      let preparedData = ref({}); 
			let ignoreLowerThan = ref(1);

      watch(graphData, () => {
        preparedData.value = prepareData();  
      });

			const refreshdata = () =>{
				preparedData.value = prepareData();  
			}

      const prepareData = () => {
        let preparedData = {

					series: [{
						name: "Počet instalací",
						data: prepareGraphDataSection(graphData.value, 10)
					}],
					chartOptions: {
						chart: {
							type: 'bar',
							height: 380
						},
						xaxis: {
							type: 'category',
							labels: {
								formatter: function(val :any) {
									return val;
								}
							},
							group: {
								style: {
									fontSize: '10px',
									fontWeight: 700
								},
								groups: [
									/*{ title: '2019', cols: 4 },
									{ title: '2020', cols: 4 }*/
								]
							}
						},
						title: {
								text: graphLabel
						},
						tooltip: {
							x: {
								formatter: function(val) {
									return "Verze: "+val;
								}  
							}
						},
					},

        }
        return preparedData;
      }

			const prepareGraphDataSection =(rawGraphdata :Array<any>, howManyMostNumerous :number)=>{
				let tempRawGraphData = JSON.parse(JSON.stringify(rawGraphdata));
				let mergedData = Object.entries(tempRawGraphData.reduce((acc :any, current :any) => {
					let { version , count} = current;
					if (acc[version]) {
						acc[version] += count;
					} else {
						acc[version] = count;
					}
					return acc;
				}, {})).map(([version, count]) => ({ version, count }));

//
			//	let maxCountValue = Math.max(...Object.values(mergedData.count));
				let countItem = mergedData.map(item => Number(item.count));
				let maxCountValue = Math.max(...countItem);
				let lowerValuesToIgnore :any = String(ignoreLowerThan.value);
				if(isNumber(ignoreLowerThan.value)){
					lowerValuesToIgnore = ignoreLowerThan.value;
				}else{
					lowerValuesToIgnore = Number(lowerValuesToIgnore.replace("%",""));
					lowerValuesToIgnore = Math.floor((maxCountValue / 100) * lowerValuesToIgnore);
					lowerValuesToIgnore = String(lowerValuesToIgnore);
				}
				mergedData = mergedData.filter(item => Number(item.count) > lowerValuesToIgnore)

				mergedData.sort((a,b)=>Number(b.count)-Number(a.count));
				let selectedData = mergedData.slice(0, howManyMostNumerous);
				let result = selectedData.map(item =>{
					let newItem = ({x: item.version, y: item.count});
					return newItem;
				})
				result.sort((a, b) => {
					let verzeA = a.x.split('.').map(Number);
					let verzeB = b.x.split('.').map(Number);
					for (let i = 0; i < Math.max(verzeA.length, verzeB.length); i++) {
						let numberA = verzeA[i] || 0;
						let numberB = verzeB[i] || 0;
						if (numberA > numberB) {
							return -1;
						} else if (numberA < numberB	) {
							return 1;
						}
					}
					return 0;
				});
				return result;
			}
			const isNumber =(str :any)=>{
				return (!isNaN(str) && !isNaN(parseFloat(str)))
			}
      return{
        prepareData,
        preparedData,
				prepareGraphDataSection,
				ignoreLowerThan,
				refreshdata
      }
    }
  });
</script>
<style>
.apexcharts-tooltip span {
	color: black !important;
}
</style>>