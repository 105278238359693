<template>
  <div class="row">
    <div class="col-12">
      <InfoUpdate :installationInfo="installationInfo"/>   
    </div>  
  </div>
</template>

<script lang="ts">
import axios from 'axios';

import { useRoute } from 'vue-router';

import { onMounted } from "vue";
import { defineComponent } from "vue";
import { ref } from 'vue';

//import DataTable from "../../components/Dashboard/Installation/InstallationTable.vue";
//import InfoDetail from "../../components/Implementatin/Installation/InfoDetail.vue";
import InfoUpdate from "../../components/Implementation/Installation/InfoUpdate.vue";

export default defineComponent({
  name: "InstallationInfoPage",
  components: {
    InfoUpdate,
  },
  setup(){
		const route = useRoute();

		let installationInfo = ref('null');
		onMounted(() => {
			getInstallationInfo(String(route.params.appuuid));
		})
		const getInstallationInfo = (appuuid :string) => {
			let params = {
        appuuid: appuuid
      }
			axios.get("/api/installationInfo",{params})
				.then((result) =>{
					if(result.data.status == 1){
						installationInfo.value = result.data.data;
					}
      })
		}
    return{
			installationInfo,
			getInstallationInfo
    }
  }

});

</script>