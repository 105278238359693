import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2bcb8063"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Detail = _resolveComponent("Detail")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Detail, {
          detailData: _ctx.detailData,
          onSelectionChange: _ctx.handleSelectionChange,
          ref: "detailRef"
        }, null, 8, ["detailData", "onSelectionChange"])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_DataTable, {
          tableData: _ctx.tableData,
          tableOptions: _ctx.tableOptions,
          onTableRestrictionChange: _ctx.handleTableRestrictionChange,
          ref: "tableDataRef"
        }, null, 8, ["tableData", "tableOptions", "onTableRestrictionChange"])
      ])
    ]),
    _createElementVNode("button", {
      class: "default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)))
    }, " Zpět ")
  ], 64))
}