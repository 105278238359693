import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card mb-25 border-0 rounded-0 bg-white" }
const _hoisted_2 = { class: "card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing" }
const _hoisted_3 = { class: "d-sm-flex align-items-center justify-content-between" }
const _hoisted_4 = { class: "fw-semibold mb-8" }
const _hoisted_5 = { class: "fw-bold" }
const _hoisted_6 = { class: "fw-bold" }
const _hoisted_7 = {
  key: 0,
  id: "taskOverviewChart",
  class: "chart"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h4", _hoisted_4, [
          _createTextVNode(" Od: "),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.borderDates.startDate), 1),
          _createTextVNode(" , Do: "),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.borderDates.endDate), 1)
        ])
      ]),
      (_ctx.preparedData.task != undefined)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_apexchart, {
              type: "bar",
              height: "370",
              options: _ctx.preparedData.taskOverviewChart,
              series: _ctx.preparedData.task
            }, null, 8, ["options", "series"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}