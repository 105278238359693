<template>
  <div class="row">
    <div class="col-12">
      <StatsBoxes :statBoxesData="statBoxesData"/>
    </div>  
  </div>
  <div class="row">
    <div class="col-12">
      <DataTable :tableData="tableData" :tableOptions="tableOptions" @tableRestrictionChange="handleTableRestrictionChange" ref="tableDataRef"/>  
    </div>   
  </div>
</template>

<script lang="ts">
import axios from 'axios';

import { onMounted } from "vue";
import { defineComponent } from "vue";
import { ref } from 'vue';

//import DataTable from "../../components/Dashboard/Product/ProductTable.vue";
import DataTable from "../../components/Common/SortableTable.vue";
import StatsBoxes from "../../components/Implementation/Installation/StatsBoxes.vue";

export default defineComponent({
  name: "DashboardPage",
  components: {
    DataTable,
    StatsBoxes,
  },
  setup(){
    let statBoxesData = ref([{}]);
    let tableData = ref([{}]);
    let tableOptions = ref({});
    let tableDataRef = ref<any>(null);

    onMounted(() => {
      getInstallationData();
      getProductDetail("all");
    })

    const handleTableRestrictionChange = () => {
      getProductDetail("all");
    };

    const getInstallationData = () => { 
      axios.post("/api/installationOverview", {
      }).then((result) =>{
        if(result.data.status == 1){
          statBoxesData.value = result.data.data.generalStats;
        }
      })
    }
    const getProductDetail = (productName :string) => { 
      let tableRestrictions = tableDataRef.value.tableRestrictions();
      let filterData = (tableRestrictions.filterData == undefined)?[]:tableRestrictions.filterData;  
      axios.post("/api/productList",{
        productName: productName,
        orderBy: tableRestrictions.orderBy,
        orderDirection: tableRestrictions.orderDirection,
        paginatorCurrentPage: tableRestrictions.paginatorCurrentPage,
        paginatorPerPage: tableRestrictions.paginatorPerPage,
        filterData: filterData
      }).then((result) =>{
        if(result.data.status == 1){
          tableOptions.value["filter"] = result.data.data.filterData;
          tableData.value = result.data.data;
          prepareTableData();
        }
      })
    }
    const prepareTableData = () =>{
      let headers = [{text: "Produkt", key: "product"},{text: "Verze", key: "version"},{text: "Počet instalací", key: "installationCount"},{text: "První instalace", key: "firstVersionDate"},{text: "Poslední instalace", key: "lastVersionDate"},{text: "Poslední appuuid", key: "lastAppuuid"}];
      let redirects = [{key: "product", redirectToName: "Product-detail", paramName: "product"}];
      tableOptions.value["headers"] = headers;
      tableOptions.value["redirects"] = redirects;
    }

    return{
      handleTableRestrictionChange,
      tableDataRef,
      statBoxesData,
      tableData,
      tableOptions,
      getInstallationData
    }
  }
});

</script>
<style scoped>
.test{
  min-height: 30px;
  text-align: center;
}
</style>>