<template>
  <div class="row justify-content-center">
    <div v-if="isRecoveryHashValid == true && isRecoverFinished == false" class="col-md-10 col-lg-8 col-xl-9 col-xxl-8 col-xxxl-6">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">
          <h4 class="text-black fw-bold mb-0 text-center">Obnovení hesla?</h4>
          <p class="mb-0 fs-md-15 fs-lg-16 text-paragraph text-center sub-text">
              Vložte Vaše nové heslo k účtu
          </p>
          <form v-on:submit.prevent>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Heslo
              </label>
              <input
                v-model="password"
                type="password"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="**************"
              />
            </div>
            <div class="form-group mb-15 mb-sm-20">
              <label class="d-block text-black fw-semibold mb-10">
                Potvrdit heslo
              </label>
              <input
                v-model="repeatedPassword"
                type="password"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="**************"
              />
            </div>
            <button
            @click="resetPassword(password, repeatedPassword)"
              class="default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 d-block w-100"
              type="submit"
            >
              Reset hesla
            </button>
            <div class="text-center mt-15 mt-sm-20 mt-md-25">
              <router-link
                to="/login"
                class="link-btn text-decoration-none text-primary fw-medium fs-md-15 fs-lg-16 position-relative"
              >
                <i class="ph-bold ph-arrow-left"></i>
                Zpět na přihlášení
              </router-link>
            </div>
            <div class="mb-0 fs-md-15 fs-lg-16 text-paragraph text-center text-danger">
              {{message}}
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-else-if="isRecoveryHashValid == true && isRecoverFinished == true" class="col-md-10 col-lg-8 col-xl-9 col-xxl-8 col-xxxl-6">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">
          <h4 class="text-black fw-bold mb-0 text-center">Obnova hesla dokončena</h4>
          <p class="mb-0 fs-md-15 fs-lg-16 text-paragraph text-center sub-text">
            Změna hesla byla dokončena. Nyní se můžete přihlásit novým heslem.
          </p>
          <div class="text-center mt-15 mt-sm-20 mt-md-25">
            <router-link
              to="/login"
              class="link-btn text-decoration-none text-primary fw-medium fs-md-15 fs-lg-16 position-relative"
            >
              <i class="ph-bold ph-arrow-left"></i>
              Zpět na přihlášení
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="col-md-10 col-lg-8 col-xl-9 col-xxl-8 col-xxxl-6">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">
          <h4 class="text-black fw-bold mb-0 text-center">Heslo nelze obnovit</h4>
          <p class="mb-0 fs-md-15 fs-lg-16 text-paragraph text-center sub-text">
              Tento odkaz pro obnovu hesla není platný. Znovu prosím proveďte obnovu hesla.
          </p>
          <div class="text-center mt-15 mt-sm-20 mt-md-25">
            <router-link
              to="/login"
              class="link-btn text-decoration-none text-primary fw-medium fs-md-15 fs-lg-16 position-relative"
            >
              <i class="ph-bold ph-arrow-left"></i>
              Zpět na přihlášení
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
export default {
  name: "ResetPassword",
  setup(){
    let message = ref("");
    let password = ref("");
    let repeatedPassword = ref("");
    let isRecoveryHashValid = ref(null);
    let isRecoverFinished = ref(false);

    //Router imports
    const route = useRoute()
    const router = useRouter();

    onMounted(()=>{
      let params = route.params;
      let recoveryHash = params.recoveryHash;
      checkRecoveryHash(recoveryHash);
    })

    const isPasswordMatch = (password, repeatedPassword) =>{
      if(password == repeatedPassword){
        return true;
      }else{
        return false;
      }
    }

    const checkRecoveryHash = () =>{
      let params = route.params;
      let recoveryHash = params.recoveryHash;
      axios.post("/api/checkRecoveryhash",{
        hash: recoveryHash
      })
      .then((result)=>{
        if(result.data.status == 1){
          isRecoveryHashValid.value = true;
        }else{
          isRecoveryHashValid.value = false;
        } 
      })
      .catch((error)=>{
        message.value = (error.message != undefined)?error.message:error;
      })
    }

    const resetPassword = (password, repeatedPassword) =>{
      let params = route.params;
      let recoveryHash = params.recoveryHash;

      let isMatch = isPasswordMatch(password, repeatedPassword);
      if(isMatch == true){
        axios.post("/api/recoverPassword",{
          hash: recoveryHash,
          password: password
        })
        .then((result)=>{
          if(result.data.status == 1){
            isRecoverFinished.value = true;
          }else{
            message.value = result.data.message;
            setTimeout(()=>{
              message.value = "";
            },2000)
          }
        })
        .catch((error)=>{
          message.value = (error.message != undefined)?error.message:error;
            setTimeout(()=>{
              message.value = "";
            },2000)
        })
      }else{
        message.value = "Zadaná hesla se neshodují, obě musí být stejná.";
        setTimeout(()=>{
          message.value = "";
        }, 2000)
      }
    }
    return{
      password,
      repeatedPassword,
      resetPassword,
      message,
      isRecoveryHashValid,
      isRecoverFinished
    }
  }
};
</script>