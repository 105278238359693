import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SingleFilterCondition = _resolveComponent("SingleFilterCondition")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableFilter, (condition, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "col-sm-4"
        }, [
          _createVNode(_component_SingleFilterCondition, {
            condition: condition,
            onTableFilterChanged: _ctx.handleTableFilterChanged,
            ref_for: true,
            ref: "singleFilterRef"
          }, null, 8, ["condition", "onTableFilterChanged"])
        ]))
      }), 128))
    ]),
    _createElementVNode("button", {
      class: "default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clearFilter && _ctx.clearFilter(...args)))
    }, " Vyčistit filtr ")
  ], 64))
}