<template>
  <div class="row">
    <div v-for="(condition, index) in tableFilter" :key="index" class="col-sm-4">
      <SingleFilterCondition :condition="condition" @tableFilterChanged="handleTableFilterChanged" ref="singleFilterRef"></SingleFilterCondition>
    </div>
  </div>
  <button 
    class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0"
    @click="clearFilter"
  >
    Vyčistit filtr
  </button>
</template>

<script lang="ts">

import { defineComponent } from "vue";
import { ref } from 'vue';
import SingleFilterCondition from './SingleFilterCondition.vue';

export default defineComponent({
  props: ['tableFilter'],
  emits: ['tableFilterChanged'],
  name: "DashboardPage",
  components: {
    SingleFilterCondition
  },
  setup(props, context){

    let filterTypes = ref(["select","multiSelect","betweenDate", "text"])
    let filterData = ref<any>([]);
    let filterValues = {};

    let selectedFilters = ref<any[]>([]);

    let singleFilterRef = ref<any>(null);
   /* onMounted(() => {
      //prepareFilterData();
		})*/

    const handleTableFilterChanged = (localContion) =>{
      if(localContion.value != null && localContion.value != ""){
        let index = selectedFilters.value.findIndex(obj => obj["name"] == localContion.name);
        if (index != -1) {
          selectedFilters.value[index] = localContion;
        } else {
          selectedFilters.value.push(localContion);
        }
      }else{
        let index = selectedFilters.value.findIndex(obj => obj["name"] == localContion.name);
        selectedFilters.value.splice(index, 1);
      }
      let removedOptions = selectedFilters.value.map(item =>{
        let {options, ...result} = item;
        return result;
      })
      selectedFilters.value = removedOptions;
      context.emit("tableFilterChanged", selectedFilters ); 
    }

    //Hromadný reset
    const clearFilter = () =>{
      selectedFilters.value = []; 
      for(let key in singleFilterRef.value){
        singleFilterRef.value[key].resetFilter()
      }
      //context.emit("tableFilterChanged", selectedFilters); 
    }
    return{
      filterData,
      filterValues,
      handleTableFilterChanged,
      clearFilter,
      selectedFilters,
      singleFilterRef
    }
  }

});

</script>