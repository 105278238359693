import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a7b1b33c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatsBoxes = _resolveComponent("StatsBoxes")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_StatsBoxes, { statBoxesData: _ctx.statBoxesData }, null, 8, ["statBoxesData"])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_DataTable, {
          tableData: _ctx.tableData,
          tableOptions: _ctx.tableOptions,
          onTableRestrictionChange: _ctx.handleTableRestrictionChange,
          ref: "tableDataRef"
        }, null, 8, ["tableData", "tableOptions", "onTableRestrictionChange"])
      ])
    ])
  ], 64))
}