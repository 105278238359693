<template>
  <div class="row">
    <div class="col-xxl-12 col-xxxl-6">
      <div
        class="card-select mt-10 mt-md-0 mb-10 mb-md-0 d-inline-block d-sm-flex align-items-center ps-10 pe-10 pt-5 pb-5"
      >
        <label class="d-block text-black fw-semibold mb-10">Časové období:</label>
        <select
          v-model="timeSelected"
          @change = "getDashboardData(timeSelected);"
          class="form-select shadow-none fw-semibold rounded-0 test"
        >
          <option value="day" >Dnes</option>
          <option value="week" >Týden</option>
          <option value="month">Měsíc</option>
          <option value="30days" >30 dní</option>
          <option value="60days" >60 dní</option>
          <option value="90days" >90 dní</option>
          <option value="all" >Všechno</option>
        </select>
      </div>
      <WhatHappening :welcomeData = "welcomeData"/>
    </div>
      <div class="col-xxl-12 col-xxxl-6">
        <StatsBoxes :statBoxesData="statBoxesData"/>    
      </div>     
  </div>
</template>

<script lang="ts">
import axios from 'axios';

//Dayjs imports
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';


import { onMounted } from "vue";
import { defineComponent } from "vue";
import { ref } from 'vue';

import WhatHappening from "../../components/Implementation/Dashboard/WhatHappening.vue";
import StatsBoxes from "../../components/Implementation/Dashboard/StatsBoxes.vue";

export default defineComponent({
  name: "DashboardPage",
  components: {
    WhatHappening,
    StatsBoxes,
  },
  setup(){
    let statBoxesData = ref([]);
    let welcomeData = ref({mostFrequent: []});
    let timeSelected = ref("day");

    //Dayjs settings
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const dayjsInstance = dayjs().tz('Europe/Prague');

    onMounted(() => {
      getDashboardData("day");
    })

    const getBorderDates = (dateRangeName :string) => {
      let startDate :Date | null = new Date();
      let endDate :Date | null = new Date();
      endDate = dayjsInstance.startOf("day").toDate() ;
      if(dateRangeName == "30days"){
        startDate = dayjsInstance.subtract(30, 'days').startOf('day').toDate();
      }else if(dateRangeName == "60days"){
        startDate = dayjsInstance.subtract(60, 'days').startOf('day').toDate();
      }else if(dateRangeName == "90days"){
        startDate = dayjsInstance.subtract(90, 'days').startOf('day').toDate();
      }else if(dateRangeName == "day"){
        startDate = dayjsInstance.startOf('day').toDate();
        endDate = dayjsInstance.endOf('day').toDate();
      }else if(dateRangeName == "week"){
        startDate = dayjsInstance.startOf('week').toDate();
        endDate = dayjsInstance.endOf('week').toDate();
      }else if(dateRangeName == "month"){
        startDate = dayjsInstance.startOf('month').toDate();
        endDate = dayjsInstance.endOf('month').toDate();
      }else if(dateRangeName == "all"){
        startDate = null;
        endDate = null;
      }else{
        startDate = dayjsInstance.startOf('day').toDate();
      }
      return{startDate: startDate, endDate: endDate};
    }

    const getDashboardData = (dateRangeName :string) => { 
      let borderDates = getBorderDates(dateRangeName);
      axios.post("/api/dashboardData",{
          dateFrom: borderDates.startDate,
          dateTo: borderDates.endDate,
          dateRangeName: dateRangeName
      })
      .then((result) => {
        if(result.data.status == 1){
          statBoxesData.value = result.data.statsBoxData.data;
          welcomeData.value = result.data.welcomeData.data;
        }
        /* if(result.data.status == 1){
          store.dispatch("fetchLoggedUser").then(result => {
            message.value = null;
            router.push({ name: 'DashboardMain' });
          });
        }else{
          message.value = result.data.message;
        }*/
      })
      .catch((error) => {
        /* if(error.response != undefined && error.response.data != undefined){
          message.value = error.response.data.message;
        }else if(error.message != undefined){
          message.value = error.message;
        }else{
          message.value = "Nastala chyba s přihlášením."
        }*/
      })
    }

    return{
      getDashboardData,
      getBorderDates,
      statBoxesData,
      welcomeData,
      timeSelected,
      dayjsInstance
    }
  }

});

</script>
<style scoped>
.test{
  min-height: 30px;
  text-align: center;
}
</style>>