import { createStore } from 'vuex';
import  axios from 'axios'; 
const store = createStore({
  state:{
    user:
      {
        loggedIn: null,
        userName: 'host',
        rights: null
      },
  },
  getters: {
    getStoreUser(state){
      return state.user;
    },
    getStoreLoggedIn(state){
			return state.user.loggedIn;
		},
    getStoreLoggedInFunction(state){
      return function () {
        return state.user.loggedIn;
      }
    }
  },
  actions: {
    fetchLoggedUser(){
      return axios.get('/api/user')
			.then((result)=>{
        this.state.user.loggedIn = result.data.loggedIn;
        this.state.user.userName = result.data.userName;
        this.state.user.rights = result.data.rights;
				return result.data;
			}).catch(function(error){
        console.log("Nastala chyba :"+error);
      })
    },
    fetchLogout(){
        axios.get('/api/logout')
        .then(() => {
          store.dispatch("fetchLoggedUser", { self: this })
          window.location.href="/";
        })
        .catch(function(error){
          console.log("Nastala chyba: "+error);
        })
    },
  }
});

export default store;