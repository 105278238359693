<template>
  <div class="row">
		<h4>Dodatečné Info instalace:{{installationInfo.appuuid}}</h4>
		<div class="col-md-6">
			<div class="form-group mb-15 mb-sm-20 mb-md-25">
				<label class="d-block text-black fw-semibold mb-10">
					IČO
				</label>
				<input
					v-model="localData.ico"
					type="text"
					class="form-control shadow-none rounded-0 text-black"
					placeholder="IČO instalace"
				/>
			</div>
		</div>
		<div class="col-md-6">
			<div class="form-group mb-15 mb-sm-20 mb-md-25">
				<label class="d-block text-black fw-semibold mb-10">
					Název subjektu
				</label>
				<input
					v-model="localData.subject_name"
					type="text"
					class="form-control shadow-none rounded-0 text-black"
					placeholder="Název instalace"
				/>
			</div>
		</div>
		<div class="col-md-12">
			<div class="form-group mb-15 mb-sm-20 mb-md-25">
				<label class="d-block text-black fw-semibold mb-10">
					Poznámka
				</label>
				<div class="mb-0">
					<textarea
						v-model="localData.note"
						cols="15"
						rows="4"
						class="input-post d-block w-100 text-black fs-14"
						placeholder="Poznámka"
					></textarea>
				</div>
			</div>
		</div>
		<div class="col-md-12">
			<button 
				class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0"
				@click="goBack"
				>
				Zpět
			</button>
			<button 
						class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0"
						@click="updateInstallationInfo"
					>
				Uložit
			</button>
		</div>  
		<span :class="messageColorClass" class="text-center">
			{{message}}
		</span>
  </div>
</template>

<script lang="ts">
import axios from 'axios';


import { useRoute, useRouter } from 'vue-router';
import { defineComponent } from "vue";
import { ref, toRef } from 'vue';

	export default defineComponent({
	props:["installationInfo"],
	name: "InfoUpdate",
	components: {
		//InfoDetail,
	},
	setup(props){
		//Router
		const route = useRoute();
		const router = useRouter();

		//Data clone
		let localData = toRef(props, 'installationInfo');         //Reaktivní props klon - automatický update hodnoty

		//Various
		let message = ref("");
		let messageColorClass = ref("text-success");

		const updateInstallationInfo = () =>{
			let updateData = {
				note: localData.value.note,
				subjectName : localData.value.subject_name,
				ico: localData.value.ico
			}
			let appuuid = route.params.appuuid;
			axios.post("/api/updateInstallationInfo",{
				appuuid: appuuid,
				updateData
			})
			.then((result) => {
				message.value = result.data.message;
				if(result.data.status == 1){
					messageColorClass.value = "text-success";
					setTimeout(function(){
						message.value = "";
					}, 2000);
				}else{
					messageColorClass.value = "text-danger";
				}
			})
			.catch((error) =>{
				console.log(error);
			})
		}
		const goBack = () =>{
			router.go(-1);
		}
		return{
				goBack,
				localData,
				message,
				messageColorClass,
				updateInstallationInfo
		}
	}
	});

</script>