<template>
  <div class="card mb-25 border-0 rounded-0 bg-white customer-satisfaction-box">
    <div class="card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing">
      <div
        class="mb-15 mb-md-25 d-flex align-items-center justify-content-between"
      >
        <h6 class="card-title fw-bold mb-0">Přehled událostí v čase</h6>
      </div> 
      <div v-if="preparedData.inputValues != undefined" id="customerSatisfactionChart2" class="chart">
			<apexchart type="bar" height="350" :options="preparedData.chartOptions" :series="preparedData.inputValues"></apexchart>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { toRefs, ref, watch} from 'vue'

export default defineComponent({
  props: ['detailData'],
  name: "CustomerSatisfaction",

  setup(props){
    let { detailData } = toRefs(props);

    let preparedData = ref({});

		onMounted(() => {
			preparedData.value = prepareData();
		})
    watch(detailData, () => {
      preparedData.value = prepareData();
    });

    const prepareData = () => {
      let preparedData = {
				inputValues: detailData.value.inputValues,
				chartOptions: {
					chart: {
						type: 'bar',
						height: 350,
						stacked: true,
						toolbar: {
							show: true
						},
						zoom: {
							enabled: true
						}
					},
					responsive: [{
						breakpoint: 480,
						options: {
							legend: {
								position: 'bottom',
								offsetX: -10,
								offsetY: 0
							}
						}
					}],
					plotOptions: {
						bar: {
							horizontal: false,
							borderRadius: 10,
							dataLabels: {
								total: {
									enabled: true,
									style: {
										fontSize: '13px',
										fontWeight: 900
									}
								}
							}
						},
					},
					xaxis: {
						type: 'string',
						categories: detailData.value.categories
					},
					legend: {
						position: 'right',
						offsetY: 40
					},
					fill: {
						opacity: 1
					}
				},
      }
      return preparedData;
    }
    
    return{
      prepareData,
      preparedData,
    }
  }
});
</script>
<style>
.apexcharts-tooltip span {
	color: black !important;
}
</style>>