<template>
  <div class="row justify-content-center">
    <span v-if="tableOptions != undefined && tableOptions.filter != undefined">
      <Filter :tableFilter="tableOptions.filter" @tableFilterChanged="handleTableFilterChanged" ref="filterRef"/>
    </span>
    <div class="card-body p-15 p-sm-20 p-md-25"> 
      <div
        class="mb-15 mb-md-30 d-sm-flex align-items-center justify-content-between"
      >   
        <div class="row" :disabled="true">
          <!--časové filtry -->
          <!--  <div class="form-group col-6">
            <div class="input-group">
              <span class="input-group-text rounded-0 fs-14 text-muted">
                <i class="flaticon-calendar lh-1 position-relative top-1"></i>
              </span>
              <input
                type="date"
                class="form-control shadow-none rounded-0 text-black"
              />
            </div>
          </div>
          <div class="form-group col-6">
            <div class="input-group">
              <span class="input-group-text rounded-0 fs-14 text-muted">
                <i class="flaticon-calendar lh-1 position-relative top-1"></i>
              </span>
              <input
                type="date"
                class="form-control shadow-none rounded-0 text-black"
              />
            </div>
          </div>    -->
        </div>  
        <div class="row">
          <div class="align-items-center col-7">
            <div
              class="reviews-select rounded-1 d-flex align-items-center me-10 mt-10 mt-lg-0"
            >
              <span
                class="text-muted fs-12 position-relative fw-medium text-uppercase"
              >
                Zobrazit
              </span>
                <select
                  v-model="selectedMaxRecordsPerPage"
                  class="project-select form-select shadow-none fw-semibold rounded-1 mt-10 mt-sm-0 ms-sm-10"
                >
                  <option v-for="option in availableRecordsPerPage"
                    :key="option" 
                    :value="option.value">
                      {{option.text}}
                  </option>
                </select>
            </div>
          </div>    
          <div class="form-group col-5">
              <input
                type="text"
                v-model="tableFulltextFilter"
                @keyup="filterTable(tableFulltextFilter)"
                class="form-control shadow-none text-black rounded-0 border-0"
                placeholder="Filtr"
              />
      
              <!--<div class="input-group">
                <input
                  type="text"
                  v-model="tableFulltextFilter"
                  @keyup="filterTable(tableFulltextFilter)"
                  class="form-control shadow-none fw-medium ps-12 pt-8 pb-8 pe-12 letter-spacing"
                  placeholder="Filtr"
                />
                <button
                  class="default-btn position-relative transition border-0 text-white ps-12 pe-12 rounded-1"
                  type="button"
                >
                  <i
                    class="flaticon-search-interface-symbol position-relative"
                  ></i>
                </button>   
              </div>--> 
    
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th v-for="(item, key) in tableOptions.headers" :key="key" 
                scope="col"
                @click="sortTable(item.key)"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                <a>{{item.text}}</a>
                <i class="lh-1 position-relative top-1" :class="(sortList.get(item.key)=='ASC'?'flaticon-chevron-3':'flaticon-chevron-2')" :style="{visibility: orderVisible(item.key)}"></i>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, key) in localTableData" :key="key" class="shadow-none fw-medium text-black product-title">
              <template v-for="(item, innerKey) in localTableData[key]" :key="innerKey">
                <td v-if="tableOptions.headers.some((element) => element.key.includes(innerKey))" class="shadow-none lh-1 fw-medium text-paragraph">
                  <a
                    v-if="tableOptions.redirects.some((element) => element.key.includes(innerKey)) && tableOptions.headers.some((element) => element.key.includes(innerKey))"
                    class="dropdown-item d-flex align-items-center fw-bold text-primary"
                    href="javascript:void(0);"  
                    @click="handleRedirect(innerKey, 
                      Array.isArray((tableOptions.redirects.find((element) => element.key.includes(innerKey))).key)
                      ? (tableOptions.redirects.find((element) => element.key.includes(innerKey))).key.map(selection => localTableData[key][selection])
                      : (item.value != undefined)?item.value:item
                      )"
                  >

                        {{capitalizeString(tableOptions.headers.find((element) => element.key.includes(innerKey)).capitalizedString, (item.text != undefined && (item.text != null && item.text).trim().length > 0)?item.text:(typeof(item)=="object")?item.value:item)}}
                  </a>
                  <span v-else-if="tableOptions.modalSelection != undefined && tableOptions.modalSelection.some((element) => element.key == innerKey)">
                    <span v-if="item.length < 4">
                      <span v-for="idList in item" :key="idList" @click="handleRedirect((tableOptions.modalSelection.find(element => element.key = innerKey).key), idList.value)">
                        <a
                          class="dropdown-item d-flex align-items-center fw-bold text-primary"
                          href="javascript:void(0);"  
                        >
                          {{ (idList.text != undefined && (idList.text).trim().length > 0)?idList.text:(idList.text != undefined && (idList.text).trim().length==0 || idList.text == null)?idList.value:idList}}

                        </a>
                      </span>
                    </span>
                    <span v-else-if="tableOptions.modalSelection != undefined">
                      <button @click="triggerInstallationSelection(tableOptions.modalSelection.find(element => element.key = innerKey), item)" class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0">Vybrat instalaci</button>
                    </span>
                  </span>
                  <span v-else class="word-breaker">
                    {{handleDateTransform(item)}}
                  </span>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
        <div
          class="d-md-flex mt-15 mt-md-25 mb-0 justify-content-between align-items-center"
        >
          <!--  <span
            href="#"
            class="card-link-btn text-decoration-none text-primary fw-medium position-relative d-inline-block"
            >
            Levá strana pod tabulkou
          </span>-->
          <span class="mb-0 text-paragraph">
            Celkový počet záznamů: <span class="fw-bold"> {{tableData.totalRecords}} </span>
          </span>
          <div
            class="pagination-area d-md-flex align-items-center mt-10 mt-md-0"
          >
            <p class="mb-0 text-paragraph">
              Zobrazuji <span class="fw-bold">{{paginatorCurrentPage}}</span> ze
              <span class="fw-bold">{{totalPaginatorTabs}}</span>
            </p>
            <nav class="mt-12 mt-md-0 ms-md-20">
              <ul class="pagination mb-0">
                <li class="page-item">
                  <a class="page-link" aria-label="Previous" @click="paginatorCurrentPage=(paginatorCurrentPage>1)?paginatorCurrentPage-1:paginatorCurrentPage">
                    <i
                      class="flaticon-chevron-1">
                    </i>
                  </a>
                </li>
                <li v-for="index in availablePaginatorGroups" :key="index" class="page-item" >
                  <a class="page-link" :class="(index == paginatorCurrentPage)?'active':''" @click="changePaginatorPage(index)">{{index}}</a> 
                </li>
                <li class="page-item">
                  <a class="page-link" aria-label="Next">
                    <i
                      @click="paginatorCurrentPage=(paginatorCurrentPage<totalPaginatorTabs)?paginatorCurrentPage+1:paginatorCurrentPage"
                      class="flaticon-chevron">
                    </i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
  <SelectionModal ref="selectionRef"/>
</template>

<script lang="ts">
//Vue import
import { defineComponent } from "vue";
import { useRouter } from 'vue-router'
import { ref, watch,  computed  } from 'vue';

//Component import
import SelectionModal from "../Common/SelectionModal.vue";
import Filter from "../Common/TableFilter.vue";

//Dayjs imports
import dayjs from 'dayjs';

export default defineComponent({
  props: ['tableData', 'tableOptions'],
  name: "installationTable",
  emits: ['tableRestrictionChange'],
  components: {
    SelectionModal,
    Filter
  },

  setup(props, context){
    const router = useRouter();

    //let localTableData = toRef(props, 'tableData');         //Reaktivní props klon - automatický update hodnoty
    let localTableData = ref(props.tableData.result);             //Reaktivní props klon - nutný manuální update hodnoty

    //let orderBy = ref<any>(null);
    let orderBy = ref<any>(null);
    let orderDirection = ref<any>(null);
    let paginatorCurrentPage = ref(1);
    let paginatorPerPage = ref(50);
    let totalPaginatorTabs = ref(3);

    let selectedMaxRecordsPerPage = ref<string>("")
    let selectionRef = ref<any>(null);
    let filterData = ref<any>({});

    let currentSortAttribute = ref("");
    let sortList = ref(new Map);
    let tableFulltextFilter = ref();
    
    watch(() => props.tableData, (newValue, oldValue) => {
      localTableData.value = (newValue != undefined && newValue.result != undefined)?newValue.result:newValue;
      createSortList();
      calculatePaginatorPages();
      if(selectedMaxRecordsPerPage.value == ""){
        selectedMaxRecordsPerPage.value = availableRecordsPerPage.value[0].value;
      }  
    })
    watch(() => props.tableData.result, (newValue, oldValue) => {
      if(newValue != undefined){
        localTableData.value = (newValue != undefined && newValue.result != undefined)?newValue.result:newValue;
        createSortList();
        calculatePaginatorPages();
        if(selectedMaxRecordsPerPage.value == ""){
          selectedMaxRecordsPerPage.value = availableRecordsPerPage.value[0].value;
        } 
      }
    })
    watch([orderBy, orderDirection, paginatorCurrentPage, selectedMaxRecordsPerPage], (newValues, oldValues) => {
      paginatorPerPage.value = (selectedMaxRecordsPerPage.value == "all")?props.tableData.totalRecords:Number(selectedMaxRecordsPerPage.value);
      if(oldValues[3] != ""){
        context.emit('tableRestrictionChange');
      }
      calculatePaginatorPages();
    });

    const handleTableFilterChanged = (data) =>{
      filterData.value = data;
      context.emit('tableRestrictionChange');
    }

    const handleRedirect = (redirectKey :string, itemValue :string) => {
      let foundRedirect = props.tableOptions.redirects.find((element) => element.key.includes(redirectKey));    
      if(foundRedirect != undefined){
        let redirectTo = foundRedirect.redirectToName;
        let paramName = foundRedirect.paramName;
        let paramValue = {};
        if(Array.isArray(foundRedirect.paramName)){
          for(let key in paramName){
            paramValue[paramName[key]] = itemValue[key];
          }
        }else{
          paramValue =  { [paramName]: itemValue };
        }
       router.push({ name: redirectTo, params: paramValue})
      }
      if(props.tableOptions.modalSelection != undefined){
        let foundModalRedirect = props.tableOptions.modalSelection.find((element) => element.key == redirectKey);
        if(foundModalRedirect != undefined){
          let redirectTo = foundModalRedirect.redirectToName;
          let paramName = foundModalRedirect.paramName;
          router.push({ name: redirectTo, params: { [paramName]: itemValue }})
        }
      }
    }
    const triggerInstallationSelection = async (options :string, itemList :[]) =>{
      let data = itemList.map(item => ({redirectTo: options["redirectToName"], propsName: options["paramName"], propsValue: item}));
      await selectionRef.value.showModal("Výběr instalace","Výběr instalace", data).value;
    }
    const createSortList = () => {
      if(localTableData.value != undefined && localTableData.value.length > 0){
        let keys = Object.keys(localTableData.value[0]);
        for(let key in keys){
          if(keys[key] == orderBy.value){
            sortList.value.set(keys[key], orderDirection.value)
          }else{
            sortList.value.set(keys[key], "ASC")
          }      
        }
      }
    }
  
    const calculatePaginatorPages = () =>{
      if(Number(props.tableData.totalRecords > Number(paginatorPerPage.value))){
        totalPaginatorTabs.value = Math.floor(Number(props.tableData.totalRecords) / Number(paginatorPerPage.value)) + 1;
      }else{
        totalPaginatorTabs.value = 1;
      }
    }

    const sortTable = async (columnName :string) => {
      currentSortAttribute.value = columnName;
      let order = sortList.value.get(columnName);
      //Lokální třídění
      /*localTableData.value.sort(function (a :any, b :any) {
        if(order == "ASC"){
          if (a[columnName] < b[columnName]) {
            return 1;
          }
          if (a[columnName] > b[columnName]) {
            return -1;
          }
        }else{
          if (a[columnName] > b[columnName]) {
            return 1;
          }
          if (a[columnName] < b[columnName]) {
            return -1;
          }
        }
        return 0;
      });*/
      if(order == "ASC"){
        sortList.value.set(columnName, "DESC")
        orderDirection.value = "DESC";
      }else{
        sortList.value.set(columnName, "ASC")
        orderDirection.value = "ASC";
      }
      orderBy.value = columnName;
      return 0;
    }

    const filterTable = (filterInput :string) => {
      if(props.tableData.result != undefined){
        localTableData.value = props.tableData.result.filter(item1 => Object.keys(item1).some(item2 => String(item1[item2]).toLowerCase().includes(filterInput.toLowerCase())));
      }else{
        localTableData.value = props.tableData.filter(item1 => Object.keys(item1).some(item2 => String(item1[item2]).toLowerCase().includes(filterInput.toLowerCase())));
      }   
    },

    orderVisible = (orderType: string) =>{
      let orderValue = sortList.value.get(orderType);
      if(currentSortAttribute.value == orderType){
        return "visible";
      }else{
        return "hidden";
      }
    },

    changePaginatorPage = (newPaginatorPage :number) =>{
      paginatorCurrentPage.value = newPaginatorPage;
    }

    const availablePaginatorGroups = computed(() =>{
      let result :number[] = [];
      let leftLimit = Math.max(1, paginatorCurrentPage.value - 2);
      let rightLimit = Math.min(totalPaginatorTabs.value, paginatorCurrentPage.value + 2);
      for (let i = leftLimit; i <= rightLimit; i++) {
        result.push(i);
      }
      return result;
    })

    const availableRecordsPerPage = computed(() =>{
      let result :{value: string, text: string}[] = [];
      result.push({value: "50", text: "50"}) 
      result.push({value: "100", text: "100"});
      result.push({value: "200", text: "200"});
      result.push({value: "all", text: "Vše"});
      return result;
    })

    const tableRestrictions = () =>{
      if(orderBy.value == null)orderBy.value = (props.tableOptions.defaultOrder != undefined)?props.tableOptions.defaultOrder.orderBy:null;
      if(orderDirection.value == null)orderDirection.value = (props.tableOptions.defaultOrder != undefined)?props.tableOptions.defaultOrder.orderDirection:null; 
      let result = {
        paginatorCurrentPage: paginatorCurrentPage.value,
        paginatorPerPage: paginatorPerPage.value,
        orderBy: orderBy.value,
        orderDirection: orderDirection.value,
        filterData: filterData.value._value
      }
      return(result);
    }

    const isFormatedDate = (input :string) => {
      let formatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
      let date = dayjs(input);
      return formatRegex.test(input) && date.isValid();
    }

    const handleDateTransform  = (input :string) =>{
      let performTransform = isFormatedDate(input);
      if(performTransform == true){
        let formatedDate = "";
        if(input != undefined || input != null){
          let date = new Date(input);
          formatedDate = date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear();
          return formatedDate;
        }else{
          return input;
        }
      }else{
        return input;
      }
    }

    const capitalizeString = (useCapitalizedString :boolean, input :string) =>{
      if(useCapitalizedString == false || useCapitalizedString == undefined){
        return input;
      }else{
        let newString = String(input).toLowerCase();
        let firstUpperLetter = newString.charAt(0).toUpperCase();
        let remainingLetters = newString.slice(1);
        newString = firstUpperLetter + remainingLetters;
        return newString;
      }
    }
    
    return{
      orderBy,
      orderDirection,
      tableRestrictions,
      handleRedirect,
      sortTable,
      orderVisible,
      filterTable,
      tableFulltextFilter,
      currentSortAttribute,
      localTableData,
      totalPaginatorTabs,
      paginatorCurrentPage,
      sortList,
      changePaginatorPage,
      availablePaginatorGroups,
      availableRecordsPerPage,
      selectedMaxRecordsPerPage,
      selectionRef,
      triggerInstallationSelection,
      handleTableFilterChanged,
      handleDateTransform,
      capitalizeString
    }
  }
  

});
</script>

<style scoped>
 .word-breaker {
    width:250px;
    word-wrap:break-word;
    white-space: normal;
 }
</style>