<template>
  <div class="card mb-25 border-0 rounded-0 welcome-box">
    <div class="card-body pe-15 pe-sm-20 pe-md-0 pb-0 pt-15 pt-sm-20">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6">
          <div class="title position-relative">
            <h3 class="fw-semibold mb-8">
              Vítejte, <span class="fw-bold">{{user.userName}}!</span>
            </h3>
            <span class="d-block text-black-emphasis fs-md-15 fs-lg-16">
              Aktuální informace
            </span>
          </div>
          <ul class="ps-0 mb-0 list-unstyled">
            <li
              class="d-inline-block text-uppercase fw-medium fs-13 text-black-emphasis position-relative"
            >
              {{timeRangeSelectedText}} počet událostí
              <span class="d-block fw-black lh-1 text-black mt-5 mt-md-10">
                {{welcomeData.totalCount}}
              </span>
            </li>
            <li
              class="d-inline-block text-uppercase fw-medium fs-13 text-black-emphasis position-relative"
            >
              Nejčastější událost
              <span class="d-block fw-black lh-1 text-black mt-5 mt-md-10">
                {{welcomeData.mostFrequent.join()}}
              </span>
            </li>
          </ul>
        </div>
        <div class="col-lg-6 col-md-6 text-center mt-15 mt-md-0">
          <img
            src="../../../assets/images/welcome/welcome1.png"
            alt="welcome-image"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { useStore } from 'vuex';
import { useRouter } from 'vue-router'
import { computed } from 'vue';

export default 
  defineComponent({
    props: ['welcomeData'],
    name: "WhatHappening",

  setup(props){
    const router = useRouter()
    const store = useStore();

    const user = computed(() => store.getters.getStoreUser);
    const timeRangeSelectedText = computed(() =>{
      if(props.welcomeData.selectionName ==  "day"){
        return "dnešní"
      }else if(props.welcomeData.selectionName == "week"){
        return "týdenní";
      }else if(props.welcomeData.selectionName == "month"){
        return "měsíční";
      }else if(props.welcomeData.selectionName == "30days"){
        return "30-ti denní";
      }else if(props.welcomeData.selectionName == "60days"){
        return "60-ti denní";
      }else{
        return "";
      }
    })
    return{
      user,
      timeRangeSelectedText
    }
  },
})
</script>