<template>
  <ForgotPasswordForm />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ForgotPasswordForm from "../../components/Authentication/ForgotPassword/ForgotPassword.vue";

export default defineComponent({
  name: "ForgotPasswordPage",
  components: {
    ForgotPasswordForm,
  },
});
</script>