<template>
  <template v-if="user.loggedIn">
    <MainHeader />
    <MainSidebar />
  </template>
  <div class="main-content d-flex flex-column transition overflow-hidden">
    <router-view />
    <template v-if="user.loggedIn">
      <MainFooter />
      <!--<SocketInit/>-->
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, watchEffect } from "vue";
import stateStore from "./utils/store";

import MainHeader from "./components/Layouts/MainHeader.vue";
import MainSidebar from "./components/Layouts/MainSidebar.vue";
import MainFooter from "./components/Layouts/MainFooter.vue";
//Sockety odkomentovat až budou někde využity
//import SocketInit from "./utils/SocketInit.vue";

import { computed } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: "App",
  components: {
    MainHeader,
    MainSidebar,
    MainFooter,
    //SocketInit
  },
  mounted() {
    document.body.classList.add("bg-body-secondary");

  },
  setup() {
    const stateStoreInstance = stateStore;
    const store = useStore();
    const user = computed(() => store.getters.getStoreUser)

    store.dispatch("fetchLoggedUser", { self: this })
    
    watchEffect(() => {
      if (stateStoreInstance.open) {
        document.body.classList.remove("sidebar-show");
        document.body.classList.add("sidebar-hide");
      } else {
        document.body.classList.remove("sidebar-hide");
        document.body.classList.add("sidebar-show");
      }
    });
    return {user};
  },
});
</script>