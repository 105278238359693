<template>
  <ResetPasswordForm />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ResetPasswordForm from "../../components/Authentication/ResetPassword/ResetPassword.vue";

export default defineComponent({
  name: "ResetPasswordPage",
  components: {
    ResetPasswordForm,
  },
});
</script>