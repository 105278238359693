<template>
  <div class="row"> 
    <h4 class="fw-semibold mb-8">
      Produkt:
      <span class="fw-bold">{{route.params.product}}</span>
       , 
      Evidence:
      <span class="fw-bold">{{route.params.evidence}}</span>
    </h4>
    <span class="col-12 col-sm-7 mb-15 mb-md-30 d-sm-flex align-items-center justify-content-between" :disabled="true">
      <div class="form-group col-6">
        <div class="input-group">
          <span class="input-group-text rounded-0 fs-14 text-muted">
            <i class="flaticon-calendar lh-1 position-relative top-1"></i>
          </span>
          <input
            v-model="borderDates.startDate"
            @change="setCustomDateSelection"
            type="date"
            class="form-control shadow-none rounded-0 text-black me-1"
          />
        </div>
      </div>
      <div class="form-group col-6">
        <div class="input-group">
          <span class="input-group-text rounded-0 fs-14 text-muted">
            <i class="flaticon-calendar lh-1 position-relative top-1"></i>
          </span>
          <input
            v-model="borderDates.endDate"
            @change="setCustomDateSelection"
            type="date"
            class="form-control shadow-none rounded-0 text-black me-1"
          />
        </div>
      </div>
    </span>

    <span class="col-12 col-sm-5 mb-15 mb-md-30 d-sm-flex align-items-center justify-content-between">
      <label class="d-block text-black fw-semibold mb-10">Časové období:</label>
      <select
        v-model="timeSelected"
        @change="changeBorderDates(timeSelected)"
        class="form-select shadow-none fw-semibold rounded-0 test"
      >
        <option value="day" >Dnes</option>
        <option value="week" >Týden</option>
        <option value="month">Měsíc</option>
        <option value="30days" >30 dní</option>
        <option value="60days" >60 dní</option>
        <option value="90days" >90 dní</option>
        <option value="120days" >120 dní</option>
        <option value="custom">Vlastní</option>
      </select>
    </span>
    <div class="col-12">
      <Detail :detailData="detailData" :borderDates="borderDates"/>
    </div>  
  </div>
</template>
<script lang="ts">

import axios from 'axios';


import { onMounted } from "vue";
import { defineComponent } from "vue";
import { ref } from 'vue';
import { useRoute } from 'vue-router';

//Dayjs imports
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import Detail from "../../components/Implementation/Evidence/Detail.vue";

export default defineComponent({
  name: "DashboardPage",
  components: {
    Detail,
  },
  setup(){
    let detailData = ref({products: []});
    const route = useRoute()

    //Dayjs settings
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const dayjsInstance = dayjs().tz('Europe/Prague');

    //Time related variables
    let timeSelected = ref("120days");
    let borderDates = ref<{ startDate: any, endDate: any }>({ startDate: new Date().toISOString().split("T")[0], endDate: new Date().toISOString().split("T")[0] });

    onMounted(() => {
      changeBorderDates(timeSelected.value)
    })
    const getEvidenceDetailData = (product :string, evidence :string, dateFrom :Date, dateTo :Date) => { 
      axios.post("/api/evidenceDetail",{
        product: product,
        evidence: evidence,
        dateFrom: dateFrom,
        dateTo: dateTo
      })
      .then((result) =>{
        if(result.data.status == 1){
          detailData.value = result.data.data;
        }
      })
    }
    const changeBorderDates = (dateRangeName :string) => {
      let startDate :Date;
      if(dateRangeName == "custom") return "done";
      let endDate = dayjsInstance.startOf("day").toDate();
      if(dateRangeName == "30days"){
        startDate = dayjsInstance.subtract(30, 'days').startOf('day').toDate();
      }else if(dateRangeName == "60days"){
        startDate = dayjsInstance.subtract(60, 'days').startOf('day').toDate();
      }else if(dateRangeName == "90days"){
        startDate = dayjsInstance.subtract(90, 'days').startOf('day').toDate();
      }else if(dateRangeName == "120days"){
        startDate = dayjsInstance.subtract(120, 'days').startOf('day').toDate();
      }else if(dateRangeName == "day"){
        startDate = dayjsInstance.startOf('day').toDate();
        endDate = dayjsInstance.endOf('day').toDate();
      }else if(dateRangeName == "week"){
        startDate = dayjsInstance.startOf('week').toDate();
        endDate = dayjsInstance.endOf('week').toDate();
      }else if(dateRangeName == "month"){
        startDate = dayjsInstance.startOf('month').toDate();
        endDate = dayjsInstance.endOf('month').toDate();
      }else{
        startDate = dayjsInstance.startOf('day').toDate();
      }
      borderDates.value = {startDate: startDate.toISOString().split("T")[0], endDate: endDate.toISOString().split("T")[0]};
      let product = (route.params.product) as string;
      let evidence = (route.params.evidence) as string;
      let dateFrom = (borderDates.value.startDate);
      let dateTo = (borderDates.value.endDate);
      getEvidenceDetailData(product, evidence, dateFrom, dateTo);
      //getEvidenceData(timeSelected.value, overViewData.value.productList[selectedProductIndex.value].value, isDemoLicense.value);
      return "done";
    }
    const setCustomDateSelection = () =>{
      timeSelected.value = "custom";
      let product = (route.params.product) as string;
      let evidence = (route.params.evidence) as string;
      let dateFrom = (borderDates.value.startDate);
      let dateTo = (borderDates.value.endDate);
      getEvidenceDetailData(product, evidence, dateFrom, dateTo);
    }
    return{
      detailData,
      route,
      changeBorderDates,
      borderDates,
      dayjsInstance,
      timeSelected,
      setCustomDateSelection
    }
  }
});

</script>
<style scoped>
.test{
  min-height: 30px;
  text-align: center;
}
</style>>