<template>
<div class="card mb-25 border-0 rounded-0 bg-white customer-satisfaction-box">
	<div class="card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing">
		<div
			class="mb-15 mb-md-25 d-flex align-items-center justify-content-between"
		>
			<h6 class="card-title fw-bold mb-0">Přehled událostí v čase</h6>
		</div> 
		<div v-if="optionTables !=null">
			<VueMultiselect v-model="selectedTables" :options="optionTables" :multiple="true" display="value" label="name" track-by="value" />
		</div>
		<div v-if="preparedData.inputValues != undefined" class="chart">
		<apexchart type="line" height="350" :options="preparedData.chartOptions" :series="preparedData.inputValues"></apexchart>
		</div>
	</div>
</div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { toRefs, ref, watch} from 'vue'
import VueMultiselect from 'vue-multiselect';

export default defineComponent({
	props: ['detailData'],
	name: "CustomerSatisfaction",
	components: {
		VueMultiselect
	},
setup(props){

	let { detailData } = toRefs(props);
	let preparedData = ref({});
	let selectedTables = ref<{value: string, name: string, data: number[]}[]>([])
	let optionTables = ref(null);

	onMounted(() => {
		selectedTables.value = prepareOptionValues();
		//TODO - link selected - fyzicky zobrazene !!
		preparedData.value = prepareData();
		optionTables.value = prepareOptionValues();
	})

	watch(detailData, () => {
		selectedTables.value = prepareOptionValues();
		preparedData.value = prepareData();
		optionTables.value = prepareOptionValues();
	});

	watch(selectedTables, () =>{
		preparedData.value = prepareData();
		optionTables.value = prepareOptionValues();
	}, { deep: true })

	const prepareData = () => {
		let preparedData = {
			inputValues: selectedTables.value,
			chartOptions: {
				chart: {
					height: 350,
					type: 'line',
					zoom: {
						enabled: false
					}
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					curve: 'straight'
				},
				title: {
					text: 'Tabulky',
					align: 'left'
				},
				grid: {
					row: {
					colors: ['#f3f3f3', 'transparent'], 
					opacity: 0.5
					},
				},
				xaxis: {
					categories: detailData.value.categories
				}
			},
		}
		return preparedData;
	}

	const prepareOptionValues = () =>{	
		return detailData.value.inputValues.map(item => ({value: item.name, name: item.name, data: item.data}))
	}
	
	return{
		prepareData,
		preparedData,
		selectedTables,
		optionTables
	}
}
});
</script>
<style>
.apexcharts-tooltip span {
color: black !important;
}
</style>>